import React, { useState } from "react";
import Api from "../api/Api";
import { useJobDetail, ACTIONS } from "../providers/jobDetailsProvider";
import ConfirmModal from "../Utils/ConfirmModal";
import JobModal from "./JobModal";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

const JobActions = ({ job, doRefresh }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();

  const [loading, setLoading] = useState(false);
  const [jobModal, setJobModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const editJobClick = () => {
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job,
      },
    });
    setJobModal(true);
  };

  const deleteJob = async () => {
    setLoading(true);
    try {
      await Api.deleteJob(job.id);
      setLoading(false);
      setDeleteModal(false);
      doRefresh();
      toast.success("Job deleted successfully");
    } catch (err) {
      setLoading(false);
      setDeleteModal(false);
      toast.error("There was an error deleting the job");
    }
  };

  const onModalClose = () => {
    setJobModal(false);
    setDeleteModal(false);
  };

  const submitJob = async (data) => {
    setLoading(true);
    const job = {
      ...jobDetailContext.job,
      ...data,
    };
    try {
      await Api.putJob(job);
      setJobModal(false);
      doRefresh();
      toast.success("Job updated successfully");
    } catch (err) {
      const msg = err.response?.data?.length
        ? err.response.data[0].msg
        : "There was an error with your request";
      toast.error(msg);
    }
    setLoading(false);
  };

  return (
    <div
      onClick={(evt) => {
        evt.stopPropagation();
      }}
      className="d-flex text-center"
    >
      <Button
        variant="warning"
        size="sm"
        className="text-white"
        onClick={(evt) => {
          evt.stopPropagation();
          editJobClick();
        }}
      >
        <i className="fas fa-pen"></i>
      </Button>
      <Button
        variant="danger"
        size="sm"
        className="text-white ml-2"
        onClick={(evt) => {
          evt.stopPropagation();
          setDeleteModal(true);
        }}
      >
        <i className="fas fa-trash"></i>
      </Button>
      {deleteModal ? (
        <ConfirmModal
          loading={loading}
          submit={deleteJob}
          onClose={onModalClose}
          variant="danger"
          body={`Are you sure you want to delete ${
            job.so_number ? job.so_number + " / " : ""
          }${job.location}?`}
          title="Delete Job"
        />
      ) : null}
      {jobModal ? (
        <JobModal
          submitButtonLabel={"Save"}
          onSubmit={submitJob}
          onClose={onModalClose}
          showJobLeadForeman={true}
        />
      ) : null}
    </div>
  );
};

export default JobActions;
