import React, { useCallback, useEffect, useState } from "react";
import "./JobsList.scss";
import withErrorHandler from "../error/WithErrorHandler";
import { useHistory, withRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { JOB_STATUSES } from "../constants";
import Api from "../api/Api";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import JobActions from "./JobActions";

const PAGE_SIZE = 10;

const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

const JobsList = ({ search, setTotalJobs, forceRefresh }) => {
  const history = useHistory();
  const [data, setData] = useState({
    jobs: [],
    pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
  });
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("so_number");
  const [direction, setDirection] = useState(SORT_ORDER.ASC);
  const [refresh, setRefresh] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const TABLE_COLUMNS = (onSort) => [
    {
      dataField: "so_number",
      text: "SO#",
      classes: "text-truncate align-middle",
      headerStyle: { width: "15%" },
      sort: true,
      formatter: (cell) => cell || "-",
      onSort,
    },
    {
      dataField: "customer.name",
      text: "Customer Name",
      classes: "text-truncate align-middle",
      headerStyle: { width: "23%" },
      formatter: (cell) => cell || "-",
      sort: true,
      onSort,
    },
    {
      dataField: "customer.city",
      text: "Customer City / State",
      classes: "text-truncate align-middle",
      headerStyle: { width: "15%" },
      formatter: (cell) => cell || "-",
      sort: true,
      onSort,
    },
    {
      dataField: "start_date",
      text: "Start Date",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      sort: true,
      formatter: (cell) => cell || "-",
      onSort,
    },
    {
      dataField: "end_date",
      text: "End Date",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      sort: true,
      formatter: (cell) => cell || "-",
      onSort,
    },
    {
      dataField: "firstName",
      text: "Assigned To",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      sort: true,
      formatter: (cell, job) =>
        job.leadForeman
          ? `${job.leadForeman.firstName} ${job.leadForeman.lastName}`
          : "-",
      onSort,
    },
    {
      dataField: "status",
      text: "",
      headerFormatter: () => (
        <div role="columnheader">
          <select
            className="status-select font-weight-medium"
            onChange={filterStatusChange}
            value={selectedStatus}
          >
            <option value="">Status</option>
            {JOB_STATUSES.map((s, i) => (
              <option key={i} value={s.value}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      ),
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      formatter: (cell) => {
        const status = JOB_STATUSES.find((s) => s.value === cell);
        return status ? (
          <span className={`${status.class} badge p-2`}>
            {status.icon && <i className="fas fa-circle mr-2"></i>}
            {status.name}
          </span>
        ) : (
          "N/A"
        );
      },
    },
  ];

  const fetchJobs = useCallback(() => {
    setLoading(true);
    Api.getJobs({
      page: data.pagination.page,
      pageSize: data.pagination.pageSize,
      search,
      status: selectedStatus,
      sortBy,
      direction,
    })
      .then((res) => {
        setLoading(false);
        setData({
          ...res.data,
        });
        if (setTotalJobs) {
          setTotalJobs(res.data.pagination.rowCount);
        }
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  }, [
    data.pagination.page,
    data.pagination.pageSize,
    direction,
    sortBy,
    search,
    selectedStatus,
  ]);

  useEffect(() => {
    fetchJobs();
  }, [
    data.pagination.page,
    data.pagination.pageSize,
    refresh,
    search,
    sortBy,
    direction,
    forceRefresh,
    fetchJobs,
  ]);

  const filterStatusChange = (evt) => {
    setSelectedStatus(evt.currentTarget.value);
    setData({
      jobs: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const onSort = (sortByValue, directionValue) => {
    setSortBy(sortByValue);
    setDirection(directionValue);
  };

  const doRefresh = () => setRefresh(!refresh);

  const { jobs, pagination } = data;

  return (
    <div className="text-left mt-4">
      {loading ? (
        <div className="row-container">
          <div className="flex-table text-center">
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
      ) : (
        <BootstrapTable
          bootstrap4
          remote
          bordered={false}
          keyField="id"
          data={jobs}
          classes="cursor-pointer"
          headerClasses="bg-light"
          noDataIndication={() => "No data to show"}
          columns={[
            ...TABLE_COLUMNS(onSort),
            {
              dataField: "",
              text: "",
              sort: false,
              classes: "text-right",
              formatter: (cell, job) => (
                <JobActions job={job} doRefresh={doRefresh} />
              ),
            },
          ]}
          rowEvents={{
            onClick: (_, job) => history.push(`/jobs/${job.id}`),
          }}
          defaultSorted={[
            {
              dataField: sortBy,
              order: direction,
            },
          ]}
          onTableChange={() => {}}
          pagination={paginationFactory({
            page: pagination.page,
            totalSize: pagination.rowCount,
            sizePerPage: pagination.pageSize,
            sizePerPageList: [
              5,
              10,
              25,
              50,
              { text: "All", value: pagination.rowCount },
            ],
            onPageChange: (page) =>
              setData({
                ...data,
                pagination: {
                  ...data.pagination,
                  page: page,
                },
              }),
            onSizePerPageChange: (sizePerPage) =>
              setData({
                ...data,
                pagination: {
                  ...data.pagination,
                  pageSize: sizePerPage,
                  page: 1,
                },
              }),
          })}
        />
      )}
      <></>
    </div>
  );
};

export default withErrorHandler(withRouter(JobsList));
