import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import ConfirmModal from "../Utils/ConfirmModal";
import CustomerModal from "./CustomerModal";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

const CustomerActions = ({ customer, onRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const editCustomerClick = () => setShowCustomerModal(true);

  const deleteCustomerClick = () => setShowDeleteModal(true);

  const activeInactiveCustomerClick = () => setShowActiveModal(true);

  const activeInactiveCustomerSubmit = () => {
    setLoading(true);
    return Api.putCustomer({
      ...customer,
      active: !customer.active,
    })
      .then(() => {
        setShowActiveModal(false);
        toast.success("Customer updated successfully.");
        onRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
        setShowCustomerModal(false);
        setShowActiveModal(false);
      });
  };

  const deleteCustomer = () => {
    setLoading(true);
    Api.deleteCustomer(customer.id)
      .then(() => {
        toast.success("Customer deleted successfully.");
        setShowDeleteModal(false);
        onRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
        setShowCustomerModal(false);
        setShowActiveModal(false);
      });
  };

  const onModalClose = () => {
    setLoading(false);
    setShowCustomerModal(false);
    setShowActiveModal(false);
  };

  const submitCustomer = (data) => {
    setLoading(true);
    Api.putCustomer(data)
      .then(() => {
        setShowCustomerModal(false);
        toast.success("Customer updated successfully.");
        onRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
        setShowCustomerModal(false);
        setShowActiveModal(false);
      });
  };

  return (
    <div
      onClick={(evt) => {
        evt.stopPropagation();
      }}
      className="text-center d-flex"
    >
      <Button
        variant="warning"
        size="sm"
        className="text-white"
        onClick={(evt) => {
          evt.stopPropagation();
          editCustomerClick();
        }}
      >
        <i className="fas fa-pen"></i>
      </Button>
      <Button
        variant="dark"
        size="sm"
        className="text-white mx-2"
        onClick={(evt) => {
          evt.stopPropagation();
          activeInactiveCustomerClick();
        }}
      >
        <i className="fas fa-user-lock"></i>
      </Button>
      <Button
        variant="danger"
        size="sm"
        className="text-white"
        onClick={(evt) => {
          evt.stopPropagation();
          deleteCustomerClick();
        }}
      >
        <i className="fas fa-trash"></i>
      </Button>
      {showCustomerModal ? (
        <CustomerModal
          loading={loading}
          submit={submitCustomer}
          onClose={onModalClose}
          customer={customer || {}}
        />
      ) : null}
      {showDeleteModal ? (
        <ConfirmModal
          loading={loading}
          submit={deleteCustomer}
          onClose={onModalClose}
          variant="danger"
          body={`Are you sure you want to delete ${customer.name}?`}
          title="Delete Customer"
        />
      ) : null}
      {showActiveModal ? (
        <ConfirmModal
          loading={loading}
          submit={activeInactiveCustomerSubmit}
          onClose={onModalClose}
          variant="danger"
          body={`Are you sure you want to ${
            customer.active ? "deactivate" : "activate"
          } ${customer.name}?`}
          title={`${customer.active ? "Deactivate" : "Activate"} customer`}
        />
      ) : null}
    </div>
  );
};

export default withErrorHandler(withRouter(CustomerActions));
