import React, { useEffect, useState } from "react";
import moment from "moment";
import withErrorHandler from "../../../error/WithErrorHandler";
import ConfirmModal from "../../../Utils/ConfirmModal";

import { Button, Col, Spinner, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import BackOfficeNoteCreateEditModal from "./BackOfficeNoteCreateEditModal";

import Api from "../../../api/Api";
import config from "../../../Config";

import DocumentPreviewModal from "../../../DocumentPreviewModal";

import "./JobDetailBackOfficeNotesSection.scss";
import { useAuth } from "../../../providers/authProvider";
import { toast } from "react-toastify";
import { ACTIONS, useJobDetail } from "../../../providers/jobDetailsProvider";

const ACCORDION_KEY = "jobDetailBackOfficeNotesAccordion";

const JobDetailBackOfficeNotesSection = ({ doRefresh }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState();
  const [previewModal, setPreviewModal] = useState({});

  useEffect(() => {
    const cleanNotes = async () => {
      const allPromises = (jobDetailContext.job.notes || [])
        .filter((n) => n.creator.id !== authContext.currentUser?.id)
        .map((note) => Api.putJobNote({ ...note, seen: 1 }));
      try {
        await Promise.all(allPromises);
        doRefresh();
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
    };
    const newBackofficeNotesCount = (jobDetailContext.job.notes || []).reduce(
      (acc, item) => {
        if (
          item.seen === 0 &&
          item.creator.id !== authContext.currentUser?.id
        ) {
          acc++;
        }
        return acc;
      },
      0
    );
    if (newBackofficeNotesCount) {
      cleanNotes();
    }
  }, [
    authContext.currentUser.id,
    jobDetailContext.job,
    setJobDetailContext,
    doRefresh,
  ]);

  const deleteNoteClick = (note) => {
    setSelectedNote(note);
    setShowDeleteModal(true);
  };

  const editNoteClick = (note) => {
    setSelectedNote(note);
    setShowCreateEditModal(true);
  };

  const onPreview = (attachment, fileName) => {
    Api.signDocuments({
      documents: [
        {
          fileName,
          fileType: attachment.type,
          bucket: config.documentsBucket,
          method: "getObject",
        },
      ],
    })
      .then((result) => {
        setPreviewModal({
          isOpen: true,
          file: result?.pop().signedRequest,
          type: attachment.file_url.endsWith(".pdf") ? "pdf" : "image",
        });
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      });
  };

  const uploadAttachments = (attachmentsInfo) =>
    new Promise((resolve, reject) => {
      try {
        const readyToSignDocuments = attachmentsInfo
          .filter((att) => !att.id)
          .map((document) => {
            return {
              fileName: document.name,
              fileType: document.type,
              bucket: config.documentsBucket,
              method: "putObject",
            };
          });

        Api.signDocuments({ documents: readyToSignDocuments }).then(
          (signedURLs) => {
            const elements = signedURLs
              .filter((signedURL) => signedURL.success)
              .map((signedURL) => {
                const file = attachmentsInfo.find(
                  (document) => document.name === signedURL.fileName
                )?.file;
                return file
                  ? {
                      url: signedURL.signedRequest,
                      file,
                      fileUrl: signedURL.url,
                      type: signedURL.type,
                    }
                  : null;
              });
            const promises = elements.map((element) =>
              Api.putDocumentsToS3(element)
            );
            Promise.all(promises).then((result) => {
              const urls = result.reduce((p, c) => {
                p[c.name] = c.url;
                return p;
              }, {});
              resolve(urls);
            });
          }
        );
      } catch (error) {
        reject(error);
      }
    });

  const mergeAttachments = (urls, attachmentsInfo) => {
    const oldAttachments = attachmentsInfo
      .filter((att) => att.id)
      .map((a) => ({
        id: a.id,
        url: null,
      }));
    const newAttachments = urls.map((url) => ({
      id: null,
      url,
    }));
    return [...oldAttachments, ...newAttachments];
  };

  const saveNote = (event, noteText, attachmentsInfo) => {
    event.preventDefault();
    setLoading(true);
    setShowCreateEditModal(false);

    uploadAttachments(attachmentsInfo)
      .then((urls) => {
        const allAttacmnets = mergeAttachments(
          Object.values(urls),
          attachmentsInfo
        );
        const note = {
          job_id: jobDetailContext.job.id,
          creator_id: authContext.currentUser?.id,
          note: noteText,
          files: allAttacmnets,
        };

        Api.postJobNote(note)
          .then(() => {
            toast.success("Job Note created successfully.");
            doRefresh();
          })
          .catch((err) => {
            const msg = err.response?.data?.length
              ? err.response.data[0].msg
              : "There was an error with your request";
            toast.error(msg);
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteNote = () => {
    setLoading(true);
    Api.deleteJobNote(selectedNote.id)
      .then(() => {
        toast.success("Job Note deleted successfully.");
        doRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const editNote = (event, noteText, attachmentsInfo) => {
    event.preventDefault();
    setLoading(true);
    uploadAttachments(attachmentsInfo)
      .then((urls) => {
        const allAttacmnets = mergeAttachments(
          Object.values(urls),
          attachmentsInfo
        );
        const updatedNote = {
          id: selectedNote.id,
          note: noteText,
          files: allAttacmnets,
        };
        Api.putJobNote(updatedNote)
          .then(() => {
            toast.success("Job Note updated successfully.");
            doRefresh();
          })
          .catch((err) => {
            const msg = err.response?.data?.length
              ? err.response.data[0].msg
              : "There was an error with your request";
            toast.error(msg);
            setLoading(false);
          });
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const onDeleteModalClose = () => {
    setSelectedNote(false);
    setShowDeleteModal(false);
  };

  const renderNoteItem = (note) => {
    return (
      <AccordionItem className="accordion__item" key={note.id} uuid={note.id}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <span>{moment(note.created_at).format("MMMM DD - YYYY")}</span>
            <span className="creator-name">{`${note.creator.firstName} ${note.creator.lastName}`}</span>
            <i className="fas fa-chevron-up float-right"></i>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Row key={note.id}>
            <Col>
              <div className="note-text-container border rounded">
                {note.note}
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <b>Files</b>
                {note?.files?.length > 0 ? (
                  <div className="d-flex flex-wrap mt-3">
                    {note.files.map((att) => {
                      const splitUrl = att.file_url.split("/");
                      const name = splitUrl[splitUrl.length - 1];
                      return (
                        <div
                          key={name}
                          className="badge bg-secondary text-white ml-0 cursor-pointer"
                          onClick={() => onPreview(att, name)}
                        >
                          {name}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span>No files attached</span>
                )}
              </div>
            </Col>
            <div className="d-flex">
              <div
                className="action cursor-pointer"
                onClick={() => editNoteClick(note)}
              >
                <i className="fas fa-pencil-alt text-primary"></i>
              </div>
              <div
                className="action cursor-pointer"
                onClick={() => deleteNoteClick(note)}
              >
                <i className="fas fa-trash text-danger"></i>
              </div>
            </div>
          </Row>
        </AccordionItemPanel>
      </AccordionItem>
    );
  };

  return (
    <>
      <Row key="notes-list-row">
        {jobDetailContext.job.notes.length ? (
          <>
            <Accordion
              onChange={(preExpanded) => {
                setJobDetailContext({
                  action: ACTIONS.TOGGLE_ACCORDION,
                  payload: {
                    accordion: ACCORDION_KEY,
                    preExpanded,
                  },
                });
              }}
              preExpanded={jobDetailContext[ACCORDION_KEY]}
              className="job-note-container col-12 px-0 mt-0"
              allowZeroExpanded={true}
              allowMultipleExpanded={true}
            >
              {jobDetailContext.job.notes.map((note) => renderNoteItem(note))}
            </Accordion>
          </>
        ) : (
          <div className="flex-table row no-data-row" role="rowgroup">
            <div>No notes recorded</div>
          </div>
        )}
      </Row>
      {showCreateEditModal ? (
        <BackOfficeNoteCreateEditModal
          onClose={() => {
            setShowCreateEditModal(false);
            setSelectedNote(false);
          }}
          onSubmit={selectedNote ? editNote : saveNote}
          note={selectedNote ? selectedNote : false}
        ></BackOfficeNoteCreateEditModal>
      ) : null}
      {showDeleteModal ? (
        <ConfirmModal
          loading={loading}
          show={showDeleteModal}
          submit={deleteNote}
          onClose={onDeleteModalClose}
          variant="danger"
          body={`Are you sure you want to delete this note?`}
          title="Delete Note"
        />
      ) : null}
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : null}
      <Row key="add-notes-row">
        <Button
          onClick={() => setShowCreateEditModal(true)}
          className="btn-success float-left mt-2 ml-1"
        >
          Add Note
        </Button>
      </Row>
      {previewModal.isOpen ? (
        <DocumentPreviewModal
          file={previewModal.file}
          type={previewModal.type}
          onClose={() =>
            setPreviewModal({ isOpen: false, file: null, type: null })
          }
        />
      ) : null}
    </>
  );
};

export default withErrorHandler(JobDetailBackOfficeNotesSection);
