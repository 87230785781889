import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";

export default class CustomDateComponent {
  init(params) {
    const template = `
            <input type="text" data-input style="width: 100%;" />
            <a class="input-button" title="clear" data-clear>
                <i class="fa fa-times"></i>
            </a>`;

    this.params = params;

    this.eGui = document.createElement("div");
    this.eGui.setAttribute("role", "presentation");
    this.eGui.classList.add("ag-input-wrapper");
    this.eGui.classList.add("custom-date-filter");
    this.eGui.innerHTML = template;

    this.eInput = this.eGui.querySelector("input");

    this.picker = flatpickr(this.eGui, {
      onChange: this.onDateChanged.bind(this),
      dateFormat: "m/d/Y",
      wrap: true,
    });

    this.picker.calendarContainer.classList.add("ag-custom-component-popup");

    this.date = null;

    // this.setInputPlaceholder("mm/dd/yyyy")
  }

  getGui() {
    return this.eGui;
  }

  onDateChanged(selectedDates) {
    this.date = selectedDates[0] || null;
    this.params.onDateChanged();
  }

  getDate() {
    return this.date;
  }

  setDate(date) {
    this.picker.setDate(date);
    this.date = date;
  }

  setInputPlaceholder() {
    this.eInput.setAttribute("placeholder", "mm/dd/yyyy");
  }
}
