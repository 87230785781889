import React, { useState } from "react";
import withErrorHandler from "../../../error/WithErrorHandler";
import "./JobDetailExpenses.scss";
import { Modal } from "react-bootstrap";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { downloadFile } from "../../../Utils/Utils";
import { ACTIONS, useJobDetail } from "../../../providers/jobDetailsProvider";

const TYPE_EXPENSE_GENERAL = 1;
const TYPE_EXPENSE_PER_DIEM = 2;
const TYPE_WITHDRAWAL = 4;

const ACCORDION_KEY = "jobDetailExpensesAccordion";

const JobDetailExpenses = ({ type, reportMode }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();
  const [showPhotoModal, setShowPhotoModal] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState({});

  const getTotal = (jd) =>
    jd.transactions
      .filter((t) => t.type === type)
      .reduce((p, c) => p + c.amount, 0);

  const handlePhotoModalClose = () => {
    setShowPhotoModal();
    setSelectedPhoto({});
  };

  const handlePhotoClick = (url, label) => {
    setShowPhotoModal(true);
    setSelectedPhoto({ url, label });
  };

  return jobDetailContext.jobDays.length ? (
    <>
      <Accordion
        className="job-detail-expenses"
        allowZeroExpanded={true}
        allowMultipleExpanded={true}
        onChange={(preExpanded) => {
          setJobDetailContext({
            action: ACTIONS.TOGGLE_ACCORDION,
            payload: {
              accordion: ACCORDION_KEY,
              preExpanded,
            },
          });
        }}
        preExpanded={
          reportMode
            ? jobDetailContext.jobDays.map((jd) => jd.id)
            : jobDetailContext[ACCORDION_KEY]
        }
      >
        {jobDetailContext.jobDays.map((jd) => (
          <AccordionItem
            className={`${
              getTotal(jd) === 0
                ? "accordion__item no-print"
                : "accordion__item"
            }`}
            key={jd.id}
            uuid={jd.id}
          >
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>{moment(jd.date).format("MMMM DD - YYYY")}</span>
                <i className="fas fa-chevron-up float-right"></i>
                <span className="float-right day-expenses">
                  $ {getTotal(jd).toFixed(2)}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {!jd.transactions.filter((t) => t.type === type).length ? (
                <div className="flex-table row no-data-row" role="rowgroup">
                  <div className="px-4" role="cell">
                    No expenses recorded
                  </div>
                </div>
              ) : (
                jd.transactions
                  .filter((t) => t.type === type)
                  .map((t) =>
                    t.type === TYPE_WITHDRAWAL ? (
                      <div key={t.id}>
                        <div className="inner-section px-4">
                          <div className="d-flex flex-column">
                            <span>Withdrawal</span>
                            {t.url ? (
                              <div
                                className="figure img-thumbnail cursor-pointer mx-1"
                                onClick={() =>
                                  handlePhotoClick(t.url, "Withdrawal")
                                }
                                style={{
                                  backgroundPosition: "start",
                                  backgroundImage: `url(${t.url})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  cursor: "pointer",
                                  alignSelf: "center",
                                  height: reportMode ? "300px" : "100px",
                                  width: reportMode ? "300px" : "100px",
                                }}
                              ></div>
                            ) : null}
                          </div>
                          <span className={"positive"}>+$ {t.amount}</span>
                        </div>
                        <div className="inner-section px-4">
                          <div className="d-flex flex-column">
                            <span>Withdrawal (Fee)</span>
                            {t.url ? (
                              <div
                                className="figure img-thumbnail cursor-pointer mx-1"
                                onClick={() =>
                                  handlePhotoClick(t.url, "Withdrawal (Fee)")
                                }
                                style={{
                                  backgroundPosition: "start",
                                  backgroundImage: `url(${t.url})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  cursor: "pointer",
                                  alignSelf: "center",
                                  height: reportMode ? "300px" : "100px",
                                  width: reportMode ? "300px" : "100px",
                                }}
                              ></div>
                            ) : null}
                          </div>
                          <span className={"negative"}>-$ {t.fee}</span>
                        </div>
                      </div>
                    ) : t.type === TYPE_EXPENSE_GENERAL ? (
                      <div key={t.id} className="inner-section px-4">
                        <div className="d-flex flex-column">
                          <span>{t.description}</span>
                          {t.url ? (
                            <div
                              className="figure img-thumbnail cursor-pointer mx-1"
                              onClick={() =>
                                handlePhotoClick(t.url, t.description)
                              }
                              style={{
                                backgroundPosition: "start",
                                backgroundImage: `url(${t.url})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                cursor: "pointer",
                                alignSelf: "center",
                                height: reportMode ? "300px" : "100px",
                                width: reportMode ? "300px" : "100px",
                              }}
                            ></div>
                          ) : null}
                        </div>
                        <span className={"negative"}>-$ {t.amount}</span>
                      </div>
                    ) : t.type === TYPE_EXPENSE_PER_DIEM ? (
                      <div key={t.id} className="inner-section px-4">
                        <div className="d-flex flex-column">
                          <span className="employee-name">
                            {t.employee
                              ? `${t.employee.firstName} ${t.employee.lastName}`
                              : "N/A"}
                          </span>
                          {t.url ? (
                            <div
                              className="figure img-thumbnail cursor-pointer mx-1"
                              onClick={() =>
                                handlePhotoClick(
                                  t.url,
                                  t.employee
                                    ? `${t.employee.firstName} ${t.employee.lastName}`
                                    : "N/A"
                                )
                              }
                              style={{
                                backgroundPosition: "start",
                                backgroundImage: `url(${t.url})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                cursor: "pointer",
                                alignSelf: "center",
                                height: reportMode ? "300px" : "100px",
                                width: reportMode ? "auto" : "100px",
                              }}
                            ></div>
                          ) : null}
                        </div>
                        <div className={"negative"}>
                          <span>-${t.amount}</span>
                        </div>
                      </div>
                    ) : null
                  )
              )}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Modal show={showPhotoModal} onHide={handlePhotoModalClose}>
        <Modal.Header
          className="font-weight-medium header-photo-modal"
          closeButton
        >
          <span>{selectedPhoto.label}</span>
          <i
            className="fas fa-download"
            onClick={() => downloadFile(selectedPhoto.url)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <img alt={""} className="modal-photo-img" src={selectedPhoto.url} />
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <div className="flex-table row no-data-row" role="rowgroup">
      <div>No expenses recorded</div>
    </div>
  );
};

export default withErrorHandler(JobDetailExpenses);
