import React, { useState } from "react";
import withErrorHandler from "../../../error/WithErrorHandler";
import "./JobDetailHomeSection.scss";
import { Col, Row, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PostContract from "../../PostContract";
import { downloadFile } from "../../../Utils/Utils";

import Api from "../../../api/Api";
import { toast } from "react-toastify";
import { ACTIONS, useJobDetail } from "../../../providers/jobDetailsProvider";

const ATTACHMENT_TYPE_PHOTO = 1;
const ATTACHMENT_TYPE_NOTE = 2;
const BADGE_COLORS = ["tentative", "scheduled", "inprogress", "completed"];

const ACCORDION_KEY = "jobDetailHomeSectionAccordion";

const JobDetailHomeSection = ({ reportMode }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();
  const [showPhotoModal, setShowPhotoModal] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [pcLoading, setPcLoading] = useState();

  const handlePhotoModalClose = () => {
    setShowPhotoModal();
    setSelectedPhoto({});
  };

  const handlePhotoClick = (photo) => {
    setShowPhotoModal(true);
    setSelectedPhoto({ url: photo.content, label: photo.label });
  };

  const hasContent = (jd) =>
    jd.employeeDays.length ||
    jd.attachments.filter((a) => a.type === ATTACHMENT_TYPE_PHOTO).length ||
    jd.attachments.filter((a) => a.type === ATTACHMENT_TYPE_NOTE).length ||
    jd.workOrders.length;

  const employeeBelongsToCrew = (employeeId, crew) =>
    crew.foremans.find((f) => f.employee_id === employeeId) ||
    crew.technicians.find((t) => t.employee_id === employeeId);

  const getJobDayCrewData = (crew, jd) => ({
    employeeDays: jd.employeeDays.filter((ed) =>
      employeeBelongsToCrew(ed.employee_id, crew)
    ),
    attachments: jd.attachments.filter((at) => at.crew_id === crew.id),
  });

  const downloadPostContractPDF = async (event, id) => {
    event.stopPropagation();
    try {
      setPcLoading(true);
      const response = await Api.getPostContractPDF(id);
      const data = new Blob([response.data], { type: "application/pdf" });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.hidden = true;
      tempLink.setAttribute(
        "download",
        `postContract-${jobDetailContext.job.id}.pdf`
      );
      tempLink.click();
      setPcLoading(false);
    } catch (err) {
      const msg = err.response?.data?.length
        ? err.response.data[0].msg
        : "There was an error with your request";
      toast.error(msg);
    }
  };

  return (
    <>
      {jobDetailContext.jobDays.length ? (
        <Accordion
          allowZeroExpanded={true}
          allowMultipleExpanded={true}
          onChange={(preExpanded) => {
            setJobDetailContext({
              action: ACTIONS.TOGGLE_ACCORDION,
              payload: {
                accordion: ACCORDION_KEY,
                preExpanded,
              },
            });
          }}
          preExpanded={
            reportMode
              ? [
                  jobDetailContext.job.postContract.id,
                  ...jobDetailContext.jobDays.map((jd) => jd.id),
                ]
              : jobDetailContext[ACCORDION_KEY]
          }
        >
          {jobDetailContext.job.postContract &&
          jobDetailContext.job.postContract.id ? (
            <AccordionItem
              key={jobDetailContext.job.postContract.id}
              uuid={jobDetailContext.job.postContract.id}
            >
              {!reportMode ? (
                <AccordionItemHeading>
                  <AccordionItemButton className="pc d-flex justify-content-between job-detail-accordion-btn accordion__button bg-success">
                    <span className="font-weight-medium">Post Contract</span>
                    <div
                      onClick={(event) =>
                        downloadPostContractPDF(
                          event,
                          jobDetailContext.job.postContract.id
                        )
                      }
                    >
                      {pcLoading ? (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span className="text-white mr-2">
                          <i className="fas fa-file-download"></i>
                        </span>
                      )}
                      <i className="fas fa-chevron-up float-right"></i>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
              ) : null}
              <AccordionItemPanel className="pt-0">
                <PostContract />
              </AccordionItemPanel>
            </AccordionItem>
          ) : (
            <></>
          )}
          {jobDetailContext.jobDays.map((jd) => {
            return (
              <AccordionItem
                className={
                  hasContent(jd)
                    ? "accordion__item"
                    : "accordion__item no-print"
                }
                key={jd.id}
                uuid={jd.id}
              >
                <AccordionItemHeading>
                  <AccordionItemButton className="job-detail-accordion-btn accordion__button">
                    <span>{moment(jd.date).format("MMMM DD - YYYY")}</span>
                    <i className="fas fa-chevron-up float-right"></i>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {moment(jd.created_at).format("MM-DD-YYYY") !==
                  moment(jd.date).format("MM-DD-YYYY") ? (
                    <div className="d-flex align-items-center">
                      <span className="danger badge p-2 mb-3">
                        Created on{" "}
                        {moment(jd.created_at).format("MMMM DD - YYYY")}
                      </span>
                    </div>
                  ) : null}
                  {jobDetailContext.job.crews.map((crew, i) => {
                    const jobDayCrewData = getJobDayCrewData(crew, jd);
                    const photoAttachments = jobDayCrewData.attachments.filter(
                      (a) => a.type === ATTACHMENT_TYPE_PHOTO
                    );
                    const leadForeman = crew.foremans.find((f) => f.is_lead);
                    const leadForemanName = leadForeman
                      ? leadForeman.employee.name ||
                        `${leadForeman.employee.firstName} ${leadForeman.employee.lastName}`
                      : "No Lead";
                    return (
                      <div
                        key={i}
                        className={`border rounded px-3 ${
                          i !== 0 ? "mt-4" : ""
                        }`}
                      >
                        {jd.lead_id ? (
                          <Row className="inner-section">
                            <div className="col-12 text-left p-0 font-weight-medium d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <span className="font-weight-medium">
                                  JOB DAY CLOSED BY:
                                </span>
                                <span className="ml-2 quantity">
                                  <span className="team-member font-weight-medium">
                                    {`${jd.leadForeman?.firstName} ${jd.leadForeman?.lastName}` ||
                                      "N/A"}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </Row>
                        ) : null}
                        <Row className="inner-section">
                          <div className="col-12 text-left p-0 font-weight-medium d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="font-weight-medium">
                                TEAM MEMBERS
                              </span>
                              <span className="quantity ml-1">
                                {" "}
                                (
                                {`${jobDayCrewData.employeeDays.length}/${crew.crew_size}`}
                                )
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className={`${BADGE_COLORS[i]} badge p-2`}>
                                {leadForemanName}'s Crew
                              </span>
                            </div>
                          </div>
                          <div className="mt-1 team-members">
                            {jobDayCrewData.employeeDays.map((ed) => (
                              <span
                                key={ed.id}
                                className="team-member font-weight-medium"
                              >
                                {`${ed.employee?.firstName} ${ed.employee?.lastName}` ||
                                  "N/A"}
                              </span>
                            ))}
                          </div>
                        </Row>
                        <Row className="inner-section">
                          <div className="col-12 text-left p-0 font-weight-medium">
                            <span className="font-weight-medium">PHOTOS</span>
                            <span className="quantity ml-1">
                              {" "}
                              ({photoAttachments.length})
                            </span>
                            {!!photoAttachments.length && (
                              <i
                                className="fas fa-download ml-1"
                                onClick={() =>
                                  photoAttachments.forEach((p) =>
                                    downloadFile(p.content)
                                  )
                                }
                              ></i>
                            )}
                          </div>
                          {photoAttachments.length ? (
                            <div className="mt-2 photos-container col-12 px-0">
                              {photoAttachments.map((a) => (
                                <div
                                  key={a.id}
                                  className={
                                    reportMode
                                      ? "col-4 my-2"
                                      : "d-flex flex-column"
                                  }
                                >
                                  <div
                                    className="figure img-thumbnail cursor-pointer mx-1"
                                    onClick={() => handlePhotoClick(a)}
                                    style={{
                                      backgroundPosition: "center",
                                      backgroundImage: `url(${a.content})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      cursor: "pointer",
                                      alignSelf: "center",
                                      height: reportMode ? "300px" : "100px",
                                      width: reportMode ? "100%" : "100px",
                                    }}
                                  ></div>
                                  <span
                                    className={`font-weight-medium label ${a.label.toLowerCase()}`}
                                  >
                                    {a.label}
                                  </span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </div>
                    );
                  })}
                  {jd.workOrders.length ? (
                    <Col className="inner-section p-0">
                      <div className="mt-3">
                        {jd.workOrders.map((co) => (
                          <div className="change-order mt-3" key={co.id}>
                            <div className="text-left font-weight-medium">
                              CHANGE ORDER
                            </div>
                            <Row>
                              <Col className="col-6">
                                <div className="col-12 mt-3 employee-hours px-0">
                                  <p>
                                    <span className="font-weight-medium">
                                      Description:
                                    </span>
                                    <span className="ml-1">
                                      {co.description || "-"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-12 mt-3 employee-hours px-0">
                                  {co.parts_material ? (
                                    <p>
                                      <span className="font-weight-medium">
                                        Parts / Material:
                                      </span>
                                      <span className="ml-1">
                                        {co.parts_material}
                                      </span>
                                    </p>
                                  ) : (
                                    <span className="ml-1 text-muted">
                                      Not provided
                                    </span>
                                  )}
                                </div>
                                <div className="col-12 mt-3 employee-hours px-0">
                                  <div>
                                    <span className="font-weight-medium">
                                      Client Signature:
                                    </span>
                                    {co.signature_url ? (
                                      <p>
                                        <img
                                          alt={"signature"}
                                          className="client-signature"
                                          src={co.signature_url}
                                        />
                                        <small className="float-right">
                                          {co.signature_name}
                                        </small>
                                      </p>
                                    ) : (
                                      <span className="ml-1">Not provided</span>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-6">
                                <div className="col-12 mt-3 employee-hours">
                                  <div className="col-6 row employee-hour-container overflow-hidden">
                                    <span className="col-6 left dark-bg-primary">
                                      Foremans:{" "}
                                    </span>
                                    <span className="col-6 bg-primary">
                                      <i className="fas fa-clock"></i>
                                      {co.foreman_hours
                                        ? co.foreman_hours
                                        : "0"}{" "}
                                      hs
                                    </span>
                                  </div>
                                  <div className="col-6 row employee-hour-container overflow-hidden">
                                    <span className="col-6 dark-bg-primary">
                                      Overtime:{" "}
                                    </span>
                                    <span className="col-6 right bg-primary">
                                      <i className="fas fa-clock"></i>
                                      {co.foreman_overtime_hours
                                        ? co.foreman_overtime_hours
                                        : "0"}{" "}
                                      hs
                                    </span>
                                  </div>
                                </div>
                                <div className="col-12 mt-3 employee-hours">
                                  <div className="col-6 row employee-hour-container overflow-hidden">
                                    <span className="col-6 left dark-bg-primary">
                                      Technicians:{" "}
                                    </span>
                                    <span className="col-6 bg-primary">
                                      <i className="fas fa-clock"></i>
                                      {co.technician_hours
                                        ? co.technician_hours
                                        : "0"}{" "}
                                      hs
                                    </span>
                                  </div>
                                  <div className="col-6 row employee-hour-container overflow-hidden">
                                    <span className="col-6 dark-bg-primary">
                                      Overtime:{" "}
                                    </span>
                                    <span className="col-6 right bg-primary">
                                      <i className="fas fa-clock"></i>
                                      {co.technician_overtime_hours
                                        ? co.technician_overtime_hours
                                        : "0"}{" "}
                                      hs
                                    </span>
                                  </div>
                                </div>
                                <div className="col-12 mt-3 employee-hours">
                                  <div className="col-6 row employee-hour-container overflow-hidden">
                                    <span className="col-6 left dark-bg-primary">
                                      Management:{" "}
                                    </span>
                                    <span className="col-6 bg-primary">
                                      <i className="fas fa-clock"></i>
                                      {co.management_hours
                                        ? co.management_hours
                                        : "0"}{" "}
                                      hs
                                    </span>
                                  </div>
                                  <div className="col-6 row employee-hour-container overflow-hidden">
                                    <span className="col-6 dark-bg-primary">
                                      Overtime:{" "}
                                    </span>
                                    <span className="col-6 right bg-primary">
                                      <i className="fas fa-clock"></i>
                                      {co.management_overtime_hours
                                        ? co.management_overtime_hours
                                        : "0"}{" "}
                                      hs
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    </Col>
                  ) : null}
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      ) : (
        <div className="flex-table row no-data-row" role="rowgroup">
          <div className="flex-row" role="cell">
            No work recorded
          </div>
        </div>
      )}
      {showPhotoModal ? (
        <Modal show={true} onHide={handlePhotoModalClose}>
          <Modal.Header
            className="font-weight-medium header-photo-modal"
            closeButton
          >
            <span>{selectedPhoto.label}</span>
            <i
              className="fas fa-download"
              onClick={() => downloadFile(selectedPhoto.url)}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <img alt={""} className="modal-photo-img" src={selectedPhoto.url} />
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default withErrorHandler(JobDetailHomeSection);
