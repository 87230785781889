import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import { useAuth } from "../providers/authProvider";
import { useJobDetail } from "../providers/jobDetailsProvider";
import JobDatePicker from "./JobDatePicker";
import "./JobModal.scss";
import { toast } from "react-toastify";
import JobStatus from "./Detail/JobStatus";

const JobModal = ({
  submitButtonLabel,
  saveDraft,
  onSubmit,
  onClose,
  showJobLeadForeman,
  withSaveDraft,
}) => {
  const [jobDetailContext] = useJobDetail();
  const [authContext] = useAuth();

  const [job, setJob] = useState({});

  const [selectJobLeadForemanOptions, setSelectJobLeadForemanOptions] =
    useState([]);
  const [leadValue, setLeadValue] = useState();

  const textareaJobScopeRef = useRef(null);
  const textareaInternalNoteRef = useRef(null);

  const [customers, setCustomers] = useState([]);
  const [customerValue, setCustomerValue] = useState();

  useEffect(() => {
    if (jobDetailContext.job) {
      setJob({
        ...jobDetailContext.job,
      });
    }
  }, [jobDetailContext.job]);

  const saveJobDraft = () => {
    const data = {
      ...job,
    };
    data["creator_id"] = authContext.currentUser?.id;
    data.start_date = data.start_date
      ? moment(data.start_date).format("YYYY-MM-DD")
      : null;
    data.end_date = data.end_date
      ? moment(data.end_date).format("YYYY-MM-DD")
      : null;
    data.crews = jobDetailContext.job?.crews || [];
    delete data.customers;
    delete data.open_calendar;
    withSaveDraft && saveDraft(data);
    toast.success("Job draft saved successfully");
  };

  const customerValueChange = (evt) => {
    const attr = evt.currentTarget.getAttribute("data-value");
    const value = evt.currentTarget.value;
    setJob({
      ...job,
      customer: {
        ...job.customer,
        [attr]: value,
      },
    });
  };

  const changeTentative = (evt) =>
    setJob({
      ...job,
      tentative:
        evt.currentTarget.getAttribute("data-value") === "1" ? true : false,
    });

  const doSubmit = (event) => {
    event.preventDefault();
    const data = {
      ...job,
    };
    if (!data.tentative && (!data.start_date || !data.end_date)) {
      return toast.error("Start and end dates are mandatory for defined jobs");
    }
    if (!data.lead_id && !!data.id && showJobLeadForeman) {
      return toast.error("Please select a lead foreman");
    }
    if (!data.customer_id?.length) {
      return toast.error("Please select a customer");
    }
    data["creator_id"] = authContext.currentUser?.id;
    data.start_date = data.start_date
      ? moment(data.start_date).format("YYYY-MM-DD")
      : null;
    data.end_date = data.end_date
      ? moment(data.end_date).format("YYYY-MM-DD")
      : null;
    delete data.customers;
    delete data.open_calendar;
    return onSubmit(data);
  };

  const toggleCalendar = () =>
    setJob({
      ...job,
      open_calendar: !job.open_calendar,
    });

  const onDatesSelection = (range) =>
    setJob({
      ...job,
      start_date: range.from,
      end_date: range.to,
    });

  useEffect(() => {
    if (job.customer_id) {
      const customer = customers.find((c) => c.id === job.customer_id);
      if (customer) {
        const customerValue = { label: customer.name, value: job.customer_id };
        setCustomerValue(customerValue);
      } else {
        setCustomerValue();
      }
    }
  }, [job.customer_id, customers]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Api.getCustomers();
        setCustomers(result.data.customers);
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    textareaJobScopeRef.current.style.height = "0px";
    const scrollHeight = textareaJobScopeRef.current.scrollHeight;
    textareaJobScopeRef.current.style.height = scrollHeight + "px";
  }, [job.description]);

  useEffect(() => {
    textareaInternalNoteRef.current.style.height = "0px";
    const scrollHeight = textareaInternalNoteRef.current.scrollHeight;
    textareaInternalNoteRef.current.style.height = scrollHeight + "px";
  }, [job.internal_note]);

  useEffect(() => {
    if (job.lead_id) {
      const allForemans = (job.crews || []).flatMap((crew) => [
        ...crew.foremans,
      ]);
      const leadForeman = allForemans.find(
        (c) => c.employee_id === job.lead_id
      );
      if (leadForeman) {
        const leadValue = {
          label:
            leadForeman.employee.name ||
            `${leadForeman.employee.firstName} ${leadForeman.employee.lastName}`,
          value: job.lead_id,
        };
        setLeadValue(leadValue);
      } else {
        setLeadValue();
      }
    }
  }, [job.crews, job.lead_id]);

  useEffect(() => {
    const options = (job.crews || [])
      .map((c) => {
        const leadForeman = c.foremans.find((f) => f.is_lead);
        return leadForeman
          ? {
              key: leadForeman.employee_id,
              label:
                leadForeman.employee.name ||
                `${leadForeman.employee.firstName} ${leadForeman.employee.lastName}`,
              value: leadForeman.employee_id,
              role: leadForeman.role,
            }
          : false;
      })
      .filter(Boolean);
    setSelectJobLeadForemanOptions(options);
  }, [job.crews]);

  return (
    <Modal size="lg" className="job-modal" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {jobDetailContext.job.id ? "Edit Job" : "Create Job"}
        </Modal.Title>
        <JobStatus job={jobDetailContext.job} />
      </Modal.Header>
      <Form onSubmit={doSubmit} className="job-form">
        <Modal.Body>
          <Form.Group className="col-12 pl-0">
            <Form.Label>SO#</Form.Label>
            <Form.Control
              type="text"
              placeholder="So Number"
              value={job.so_number || ""}
              maxLength="30"
              onChange={(e) =>
                setJob({
                  ...job,
                  so_number: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="col-12 pl-0">
            <Form.Label className="col-12 px-0 d-flex justify-content-between align-items-center">
              <div>
                <span>Job Scope</span>
                <span className="text-danger ml-1">*</span>
              </div>
              <span className="text-length text-primary">{`(${
                job.description?.length || 0
              }/1024)`}</span>
            </Form.Label>
            <Form.Control
              ref={textareaJobScopeRef}
              required={true}
              as="textarea"
              placeholder="Job Scope"
              value={job.description || ""}
              maxLength="1024"
              onChange={(e) =>
                setJob({
                  ...job,
                  description: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="col-12 pl-0">
            <Form.Label className="col-12 px-0 d-flex justify-content-between align-items-center">
              <div>
                <span>Internal Note</span>
              </div>
              <span className="text-length text-primary">{`(${
                job.internal_note?.length || 0
              }/1024)`}</span>
            </Form.Label>
            <Form.Control
              ref={textareaInternalNoteRef}
              as="textarea"
              placeholder="Internal Note"
              value={job.internal_note || ""}
              maxLength="1024"
              onChange={(e) =>
                setJob({
                  ...job,
                  internal_note: e.target.value,
                })
              }
            />
          </Form.Group>
          <div className="row mx-0 d-flex justify-content-between">
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>
                <span>Customer</span>
                <span className="text-danger ml-1">*</span>
              </Form.Label>
              <Select
                required={true}
                isMulti={false}
                name="customers"
                value={customerValue}
                className="basic-multi-select flex-grow-1 mt-1"
                options={customers
                  .filter((c) => c.name.length && c.active)
                  .sort((x, y) => (x.name < y.name ? -1 : 1))
                  .map((c) => {
                    return { key: c.id, label: c.name, value: c.id };
                  })}
                classNamePrefix="select"
                onChange={(value) => {
                  const customer = customers.find((c) => c.id === value.value);
                  if (customer) {
                    setJob({
                      ...job,
                      customer_id: customer.id || "",
                      location: customer.address || "",
                      city: customer.city || "",
                      state: customer.state || "",
                      zip_code: customer.zip_code || "",
                      customer: {
                        contact_name: customer.contact_name || "",
                        email: customer.email || "",
                        number: customer.number || "",
                      },
                    });
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="col-8 pl-0 col-md-6">
              <Form.Label>Start/End Dates</Form.Label>
              <Form.Group className="mt-1 col-12 px-0 d-flex align-items-center justify-content-around">
                <Button
                  onClick={toggleCalendar}
                  variant="primary"
                  className="mr-3 ml-1 btn-sm calendar-btn bg-primary"
                >
                  <i className="far fa-calendar-alt"></i>
                </Button>
                <div className="tentative-container flex-grow-1">
                  <span
                    className={job.tentative ? "active" : ""}
                    data-value="1"
                    onClick={changeTentative}
                  >
                    Tentative
                  </span>
                  <div className="separator"></div>
                  <span
                    className={!job.tentative ? "active" : ""}
                    data-value="0"
                    onClick={changeTentative}
                  >
                    Defined
                  </span>
                </div>
              </Form.Group>
            </Form.Group>
          </div>
          {job.open_calendar ? (
            <Form.Group className="col-12 pl-0">
              <JobDatePicker
                start_date={job.start_date}
                end_date={job.end_date}
                onSelection={onDatesSelection}
                toggleCalendar={toggleCalendar}
              ></JobDatePicker>
            </Form.Group>
          ) : null}
          <div className="row mx-0 d-flex justify-content-between">
            <Form.Group className="col-12 pl-0 col-md-4">
              <Form.Label>Contact Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Name"
                maxLength="255"
                value={job.customer?.contact_name || ""}
                data-value="contact_name"
                onChange={customerValueChange}
              />
            </Form.Group>
            <Form.Group className="col-12 pl-0 col-md-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                maxLength="255"
                value={job.customer?.email || ""}
                data-value="email"
                onChange={customerValueChange}
              />
            </Form.Group>
            <Form.Group className="col-12 pl-0 col-md-4">
              <Form.Label>Phone #</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone #"
                maxLength="255"
                value={job.customer?.number || ""}
                data-value="number"
                onChange={customerValueChange}
              />
            </Form.Group>
          </div>
          <Form.Group className="col-12 pl-0">
            <Form.Label>
              <div>
                <span>Address</span>
                <span className="text-danger ml-1">*</span>
              </div>
            </Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Address"
              maxLength="255"
              value={job.location || ""}
              onChange={(e) =>
                setJob({
                  ...job,
                  location: e.target.value,
                })
              }
            />
          </Form.Group>
          <div className="row mx-0 d-flex justify-content-between">
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                maxLength="255"
                value={job.city || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    city: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                maxLength="255"
                value={job.state || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    state: e.target.value,
                  })
                }
              />
            </Form.Group>
          </div>
          <div className="row mx-0 d-flex justify-content-between">
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip Code"
                maxLength="255"
                value={job.zipcode || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    zipcode: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>Distance (+/- miles)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Distance"
                min="0"
                value={job.mileage || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    mileage: e.target.value,
                  })
                }
              />
            </Form.Group>
          </div>
          <div className="row mx-0 d-flex justify-content-between">
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>Inbound Mileage (miles)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Inbound Mileage"
                step=".01"
                min="0"
                max="99999"
                value={job.inbound_mileage || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    inbound_mileage: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>Outbound Mileage (miles)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Outbound Mileage"
                step=".01"
                min="0"
                max="99999"
                value={job.outbound_mileage || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    outbound_mileage: e.target.value,
                  })
                }
              />
            </Form.Group>
          </div>
          <div className="row mx-0 d-flex justify-content-between">
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>Inbound Flight</Form.Label>
              <Form.Control
                type="text"
                placeholder="Inbound Flight"
                maxLength="255"
                value={job.inbound_flight || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    inbound_flight: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="col-12 pl-0 col-md-6">
              <Form.Label>Outbound Flight</Form.Label>
              <Form.Control
                type="text"
                placeholder="Outbound Flight"
                maxLength="255"
                value={job.outbound_flight || ""}
                onChange={(e) =>
                  setJob({
                    ...job,
                    outbound_flight: e.target.value,
                  })
                }
              />
            </Form.Group>
          </div>
          {!!jobDetailContext.job?.id && showJobLeadForeman && (
            <Form.Group className="col-12 pl-0">
              <Form.Label>
                Job Lead Foreman:
                <span className="text-danger ml-1">*</span>
              </Form.Label>
              <Select
                required={true}
                isMulti={false}
                name="foremans"
                value={leadValue}
                className="basic-multi-select flex-grow-1 mt-1"
                options={selectJobLeadForemanOptions}
                classNamePrefix="select"
                onChange={({ value }) =>
                  setJob({
                    ...job,
                    lead_id: value,
                  })
                }
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex col-12 justify-content-between">
          <div>
            <Button size="sm" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            {withSaveDraft && (
              <Button
                size="sm"
                className="mx-1"
                variant="primary"
                onClick={saveJobDraft}
              >
                Save draft
              </Button>
            )}
          </div>
          <Button
            size="sm"
            className="bg-primary"
            variant="primary"
            type={"submit"}
          >
            {submitButtonLabel}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default withErrorHandler(JobModal);
