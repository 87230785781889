import React, { useEffect, useState } from "react";
import { Button, Form, Spinner, Row } from "react-bootstrap";
import withErrorHandler from "../error/WithErrorHandler";
import CustomerField from "./CustomerField";
import CustomerModal from "./CustomerModal";
import Api from "../api/Api";
import { useHistory, withRouter } from "react-router-dom";
import "./CustomerModal.scss";
import { toast } from "react-toastify";

const CustomerDetail = ({
  match: {
    params: { customerId },
  },
}) => {
  const history = useHistory();
  const [customer, setCustomer] = useState(null);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [localNote, setLocalNote] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      setLoading(true);
      try {
        const result = await Api.getCustomers({
          id: customerId,
        });
        if (!result.data.customers.length) {
          return;
        }
        const customer = result.data.customers[0];
        setLoading(false);
        setCustomer(customer);
        setLocalNote(customer.notes);
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
    };

    fetchCustomer();
  }, [refresh, customerId]);

  const onModalClose = () => setShowCustomerModal(false);

  const submitCustomer = async (data) => {
    setLoading(true);
    try {
      const result = await Api.putCustomer({
        ...customer,
        ...data,
      });
      if (result && result.status === 200) {
        toast.success("Customer updated successfully.");
        setLoading(false);
        setRefresh(!refresh);
        setShowCustomerModal(false);
      }
    } catch (err) {
      const msg = err.response?.data?.length
        ? err.response.data[0].msg
        : "There was an error with your request";
      toast.error(msg);
      setLoading(false);
      setShowCustomerModal(false);
      setRefresh(!refresh);
    }
  };

  if (!customer || loading) {
    return (
      <Spinner as="span" animation="border" role="status" aria-hidden="true" />
    );
  }

  return (
    <div className="job-detail p-0">
      <Row className="col-12 p-0 mb-4">
        <div className="status-container">
          <Button
            onClick={() => history.push("/customers")}
            className="bg-primary font-weight-medium"
          >
            <i className="fas fa-chevron-left"></i>Customers
          </Button>
          <span className="job-name font-weight-medium">{customer.name}</span>
          <span onClick={() => setShowCustomerModal(true)}>
            <i className="fas fa-pen text-primary cursor-pointer"></i>
          </span>
        </div>
      </Row>
      <Row className="col-12 p-0">
        <div className="read-only-form col-6">
          <Form className="customer-detail">
            <CustomerField
              name="Facility Name"
              isReadOnly
              value={customer.name}
              placeholder="Name"
              dataValue="name"
              isRequired
            />
            <CustomerField
              name="Contact Name"
              isReadOnly
              value={customer.contact_name}
              placeholder="Contact Name"
              dataValue="contact_name"
              isRequired
            />
            <CustomerField
              name="Email"
              isReadOnly
              value={customer.email}
              placeholder="Email"
              dataValue="email"
              type="email"
            />
            <CustomerField
              name="Phone Number"
              isReadOnly
              value={customer.number}
              placeholder="Phone Number"
              dataValue="number"
            />
            <CustomerField
              name="Address"
              isReadOnly
              value={customer.address}
              placeholder="Address"
              dataValue="address"
            />
            <CustomerField
              name="City"
              isReadOnly
              value={customer.city}
              placeholder="City"
              dataValue="city"
              isRequired
            />
            <CustomerField
              name="State"
              isReadOnly
              value={customer.state}
              placeholder="State"
              dataValue="state"
              isRequired
            />
            <CustomerField
              name="Secondary Name"
              isReadOnly
              value={customer.secondary_name}
              placeholder="Secondary Name"
              dataValue="secondary_name"
            />
            <CustomerField
              name="Secondary Email"
              isReadOnly
              value={customer.secondary_email}
              placeholder="Secondary Email"
              dataValue="secondary_email"
            />
            <CustomerField
              name="Secondary Phone Number"
              isReadOnly
              value={customer.secondary_number}
              placeholder="Secondary Phone Number"
              dataValue="secondary_number"
            />
            <CustomerField
              name="Secondary Address"
              isReadOnly
              value={customer.secondary_address}
              placeholder="Secondary Address"
              dataValue="secondary_address"
            />
          </Form>
        </div>
        <div className="col-6">
          <Form.Group>
            <Form.Label className="d-flex justify-content-between align-items-center font-weight-medium">
              <span>Notes</span>
              <Button
                size="sm"
                onClick={() =>
                  submitCustomer({
                    ...customer,
                    notes: localNote,
                  })
                }
              >
                Save Notes
              </Button>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={1024}
              placeholder={"Write some notes..."}
              value={localNote}
              onChange={(evt) => setLocalNote(evt.currentTarget.value)}
            ></Form.Control>
          </Form.Group>
        </div>
      </Row>
      {showCustomerModal ? (
        <CustomerModal
          loading={loading}
          submit={submitCustomer}
          onClose={onModalClose}
          customer={customer}
        />
      ) : null}
    </div>
  );
};

export default withErrorHandler(withRouter(CustomerDetail));
