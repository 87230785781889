import React from "react";
import { Alert } from "react-bootstrap";

const FallbackComponent = () => {
  return (
    <Alert variant="danger">
      <h4>Something went wrong...</h4>
    </Alert>
  );
};

export default FallbackComponent;
