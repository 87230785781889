import React from "react";
import withErrorHandler from "../error/WithErrorHandler";
import { getTimeAsHours } from "../Utils/Utils";

const TimesPayrollSection = ({ payroll_data, employees, onClick }) => {
  const employeePayroll = (e) =>
    payroll_data.find((p) => p.employee.id === e.id);

  return (
    <div className="payroll-time col-3 float-left p-0">
      <div className="flex-table header" role="rowgroup">
        <div className="flex-row col-3" role="columnheader">
          <span>Reg. Time</span>
        </div>
        <div className="flex-row col-3" role="columnheader">
          Over Time
        </div>
        <div className="flex-row col-3" role="columnheader">
          Drive Time
        </div>
        <div className="flex-row col-3" role="columnheader">
          Bonus
        </div>
      </div>
      {employees
        .sort((x, y) => (x.firstName < y.firstName ? -1 : 1))
        .map((e, i) => (
          <div key={i} className="flex-table row" role="rowgroup">
            <div className="flex-row col-3 row-bg-dark" role="cell">
              {employeePayroll(e) ? (
                <span>{getTimeAsHours(employeePayroll(e).reg_time)}</span>
              ) : (
                "-"
              )}
            </div>
            <div className="flex-row col-3 row-bg-light" role="cell">
              {employeePayroll(e) ? (
                <span>{getTimeAsHours(employeePayroll(e).ovt_time)}</span>
              ) : (
                "-"
              )}
            </div>
            <div className="flex-row col-3 row-bg-light" role="cell">
              {employeePayroll(e) ? (
                <span>
                  {getTimeAsHours(
                    employeePayroll(e).job_hours.reduce(
                      (p, c) =>
                        p + c.times.reduce((p, c) => p + c.travel_time, 0),
                      0
                    )
                  )}
                </span>
              ) : (
                "-"
              )}
            </div>
            <div className="flex-row col-3 row-bg-light" role="cell">
              {employeePayroll(e) ? (
                <span onClick={() => onClick(e.id)} className="clickable">
                  {getTimeAsHours(employeePayroll(e).bonusTime?.duration || 0)}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default withErrorHandler(TimesPayrollSection);
