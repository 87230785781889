import React, { useState } from "react";
import { Button, FormControl, InputGroup, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import { ACTIONS, useJobDetail } from "../providers/jobDetailsProvider";
import CrewSelectionModal from "./CrewSelectionModal";
import JobModal from "./JobModal";
import JobsCalendar from "./JobsCalendar";
import JobsList from "./JobsList";
import "./JobsScreen.scss";
import { toast } from "react-toastify";
import { RefreshCw } from "react-feather";

const LIST_VIEW = "1";
const CALENDAR_VIEW = "2";

const JobsScreen = () => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();

  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [view, setView] = useState(LIST_VIEW);
  const [totalJobs, setTotalJobs] = useState(0);
  const [jobModal, setJobModal] = useState(false);
  const [crewSelectionModal, setCrewSelectionModal] = useState(false);

  const changeView = (view) => setView(view);

  const searchChange = (evt) => setSearch(evt.currentTarget.value);

  const newJobClick = () => {
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job: {},
      },
    });
    setJobModal(true);
  };

  const onModalClose = () => {
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job: {},
      },
    });
    setJobModal(false);
    setCrewSelectionModal(false);
  };

  const onNext = (data) => {
    setJobModal(false);
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job: { ...jobDetailContext.job, ...data },
      },
    });
    setTimeout(() => setCrewSelectionModal(true), 100);
  };

  const onBack = (crews, lead_id) => {
    setCrewSelectionModal(false);
    const job = { ...jobDetailContext.job, crews, lead_id };
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job,
      },
    });
    setTimeout(() => setJobModal(true), 100);
  };

  const submitJob = async (crews, lead_id) => {
    const job = { ...jobDetailContext.job, crews, lead_id };
    try {
      await Api.postJob(job);
      setCrewSelectionModal(false);
      setJobModal(false);
      setJobDetailContext({
        action: ACTIONS.SET_JOB_DRAFT,
        payload: {
          jobDraft: null,
        },
      });
      setRefresh(!refresh);
      toast.success("Job created successfully");
    } catch (err) {
      toast.error("There was an error creating the job");
    }
  };

  const chargeDraftClick = () => {
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job: jobDetailContext.jobDraft || {},
      },
    });
    setJobModal(true);
  };

  const discardDraftClick = () => {
    setJobDetailContext({
      action: ACTIONS.SET_JOB_DRAFT,
      payload: {
        jobDraft: null,
      },
    });
    toast.success("Job draft discarded");
  };

  const saveDraft = (data) => {
    setJobDetailContext({
      action: ACTIONS.SET_JOB_DRAFT,
      payload: {
        jobDraft: data,
      },
    });
    setJobDetailContext({
      action: ACTIONS.SET_JOB,
      payload: {
        job: {},
      },
    });
    setJobModal(false);
    setCrewSelectionModal(false);
  };

  return (
    <div className="jobs-container">
      <Row className="controls-container pt-1">
        <h4 className="text-left float-left jobs-title">
          Jobs List ({totalJobs})
        </h4>
        <InputGroup className="col-8 col-md-6 col-lg-3 jobs-search">
          <FormControl
            placeholder="Search"
            aria-label="Search Job Name"
            onChange={searchChange}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <i className="fas fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <div className="d-flex flex-grow-1 align-items-center justify-content-between pr-4">
          <div>
            <Button
              onClick={() => changeView(LIST_VIEW)}
              variant={view === LIST_VIEW ? "primary" : "white"}
              className={`rounded-circle ${
                view === LIST_VIEW
                  ? ""
                  : "btn-white btn border text-primary border-primary"
              }`}
            >
              <i className="fas fa-columns"></i>
            </Button>
            <Button
              onClick={() => changeView(CALENDAR_VIEW)}
              variant={view === CALENDAR_VIEW ? "primary" : "white"}
              className={`ml-2 rounded-circle ${
                view === CALENDAR_VIEW
                  ? ""
                  : "btn-white btn border text-primary border-primary"
              }`}
            >
              <i className="far fa-calendar-alt"></i>
            </Button>
          </div>
          <div className="d-flex">
            <Button
              onClick={() => newJobClick()}
              variant="primary"
              className="bg-primary d-flex align-items-center rounded-circle"
            >
              <i className="fas fa-plus fa-sm"></i>
            </Button>
            {jobDetailContext.jobDraft && (
              <Button
                size="sm"
                onClick={chargeDraftClick}
                variant="success"
                className="mx-2 rounded"
              >
                Continue Draft
              </Button>
            )}
            {jobDetailContext.jobDraft && (
              <Button
                size="sm"
                onClick={discardDraftClick}
                variant="danger"
                className="rounded"
              >
                Discard Draft
              </Button>
            )}
            <Button
              size="sm"
              className="rounded-circle d-flex align-items-center custom-rounded-button-refresh ml-2"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <RefreshCw size={18} />
            </Button>
          </div>
        </div>
      </Row>
      {view === LIST_VIEW ? (
        <JobsList
          search={search}
          setTotalJobs={(totalJobs) => setTotalJobs(totalJobs)}
          forceRefresh={refresh}
        />
      ) : (
        <JobsCalendar
          search={search}
          setTotalJobs={(totalJobs) => setTotalJobs(totalJobs)}
        />
      )}
      {jobModal ? (
        <JobModal
          submitButtonLabel={
            <span>
              Crews <i className="ml-2 fas fa-chevron-right"></i>
            </span>
          }
          onSubmit={onNext}
          withSaveDraft={true}
          saveDraft={saveDraft}
          onClose={onModalClose}
          showJobLeadForeman={false}
        />
      ) : null}
      {crewSelectionModal ? (
        <CrewSelectionModal
          onSubmit={submitJob}
          onClose={onModalClose}
          onBack={onBack}
        />
      ) : null}
    </div>
  );
};

export default withErrorHandler(withRouter(JobsScreen));
