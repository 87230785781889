import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import withErrorHandler from "../error/WithErrorHandler";
import CustomerField from "./CustomerField";
import "./CustomerModal.scss";

const CustomerModal = ({ onClose, submit, loading, customer, title }) => {
  const [currentCustomer, setCurrentCustomer] = useState(
    customer ? customer : {}
  );

  const handleClose = () => onClose();

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (loading) {
      return;
    }
    return submit(currentCustomer);
  };

  return (
    <Modal className="customer-modal" show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title ?? (customer?.id ? "Editing Customer" : "New Customer")}
        </Modal.Title>
      </Modal.Header>
      <Form className="customer-form" onSubmit={onSubmit}>
        <Modal.Body className="d-flex flex-wrap row">
          <CustomerField
            name="Facility Name"
            value={currentCustomer.name}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                name: evt.currentTarget.value,
              })
            }
            placeholder="Name"
            dataValue="name"
            groupClasses="col-12 col-md-6"
            isRequired
          />
          <CustomerField
            name="Contact Name"
            value={currentCustomer.contact_name}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                contact_name: evt.currentTarget.value,
              })
            }
            placeholder="Contact Name"
            dataValue="contact_name"
            groupClasses="col-12 col-md-6"
            isRequired
          />
          <CustomerField
            name="Email"
            value={currentCustomer.email}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                email: evt.currentTarget.value,
              })
            }
            placeholder="Email"
            dataValue="email"
            type="email"
            groupClasses="col-12 col-md-6"
          />
          <CustomerField
            name="Phone Number"
            value={currentCustomer.number}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                number: evt.currentTarget.value,
              })
            }
            placeholder="Phone Number"
            dataValue="number"
            groupClasses="col-12 col-md-6"
          />
          <CustomerField
            name="Address"
            value={currentCustomer.address}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                address: evt.currentTarget.value,
              })
            }
            placeholder="Address"
            dataValue="address"
          />
          <CustomerField
            name="City"
            value={currentCustomer.city}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                city: evt.currentTarget.value,
              })
            }
            placeholder="City"
            dataValue="city"
            groupClasses="col-6"
            isRequired
          />
          <CustomerField
            name="State"
            value={currentCustomer.state}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                state: evt.currentTarget.value,
              })
            }
            placeholder="State"
            dataValue="state"
            groupClasses="col-6"
            isRequired
          />
          <CustomerField
            name="Secondary Name"
            value={currentCustomer.secondary_name}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                secondary_name: evt.currentTarget.value,
              })
            }
            placeholder="Secondary Name"
            dataValue="secondary_name"
            groupClasses="col-6"
          />
          <CustomerField
            name="Secondary Email"
            value={currentCustomer.secondary_email}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                secondary_email: evt.currentTarget.value,
              })
            }
            placeholder="Secondary Email"
            dataValue="secondary_email"
            groupClasses="col-6"
          />
          <CustomerField
            name="Secondary Phone Number"
            value={currentCustomer.secondary_number}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                secondary_number: evt.currentTarget.value,
              })
            }
            placeholder="Secondary Phone Number"
            dataValue="secondary_number"
            groupClasses="col-6"
          />
          <CustomerField
            name="Secondary Address"
            value={currentCustomer.secondary_address}
            valueChange={(evt) =>
              setCurrentCustomer({
                ...currentCustomer,
                secondary_address: evt.currentTarget.value,
              })
            }
            placeholder="Secondary Address"
            dataValue="secondary_address"
            groupClasses="col-6"
          />
          <Form.Group className="col-12">
            <Form.Label className="float-left">Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={1024}
              placeholder={"Write some notes..."}
              value={currentCustomer.notes || ""}
              onChange={(evt) =>
                setCurrentCustomer({
                  ...currentCustomer,
                  notes: evt.currentTarget.value,
                })
              }
            ></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between col-12">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="bg-primary" variant="primary" type="submit">
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default withErrorHandler(CustomerModal);
