import React, { useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { pdfjs, Document, Page } from "react-pdf";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentPreviewModal = ({ onClose, type, file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header>
        {`${type === "pdf" ? "Document" : "Image"} Preview`}
      </Modal.Header>
      {type === "pdf" ? (
        <Modal.Body className="p-0 text-center">
          <Document
            className="d-flex justify-content-center"
            file={file}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="d-flex justify-content-between p-3 align-items-center text-body">
            <FontAwesomeIcon
              className="cursor-pointer text-body"
              icon={faChevronLeft}
              onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
            />
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <FontAwesomeIcon
              className="cursor-pointer text-body"
              icon={faChevronRight}
              onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
            />
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="p-0 text-center">
          <img src={file} width={700} alt="Attached to this Job Note." />
        </Modal.Body>
      )}
      <Modal.Footer className="justify-content-start">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentPreviewModal;
