import React, { useState } from "react";
import withErrorHandler from "../error/WithErrorHandler";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import {
  EMPLOYEE_ROLES,
  EMPLOYEE_CATEGORIES,
  USER_ROLE_TECHNICIAN,
  USER_ROLE_EXECUTIVE,
  CATEGORY_INSURED,
} from "../constants";
import "./EmployeeModal.scss";
import { getRole } from "../Utils/Utils";

const EmployeeModal = ({ employee, onClose, submit, loading }) => {
  const [localEmployee, setLocalEmployee] = useState({ ...employee });

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (loading) {
      return;
    }
    return submit({
      ...localEmployee,
      category_id:
        localEmployee.role === USER_ROLE_EXECUTIVE
          ? CATEGORY_INSURED
          : localEmployee.category_id,
    });
  };

  return (
    <Modal className="employee-modal" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {employee?.id ? "Editing Employee" : "New Employee"}
        </Modal.Title>
        {getRole(employee)}
      </Modal.Header>
      <Form className="d-fle flex-column" onSubmit={onSubmit}>
        <Modal.Body className="d-flex flex-wrap font-size-14">
          <Form.Group className="col-12 pl-0 col-md-6">
            <Form.Label>
              First Name
              <small className="text-danger ml-1">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={localEmployee.firstName}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  firstName: evt.currentTarget.value,
                })
              }
              required={true}
            />
          </Form.Group>
          <Form.Group className="col-12 pr-0 col-md-6">
            <Form.Label>
              Last Name
              <small className="text-danger ml-1">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={localEmployee.lastName}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  lastName: evt.currentTarget.value,
                })
              }
              required={true}
            />
          </Form.Group>
          <Form.Group className="col-12 pl-0 col-md-6">
            <Form.Label>
              Phone Number
              <small className="text-danger ml-1">*</small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone Number"
              value={localEmployee.contact}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  contact: evt.currentTarget.value,
                })
              }
              required={true}
            />
          </Form.Group>
          <Form.Group className="col-12 pr-0 col-md-6">
            <Form.Label>
              Email
              {localEmployee.role &&
              localEmployee.role !== USER_ROLE_TECHNICIAN ? (
                <small className="text-danger ml-1">*</small>
              ) : null}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              value={localEmployee.email}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  email: evt.currentTarget.value,
                })
              }
              required={
                localEmployee.role &&
                localEmployee.role !== USER_ROLE_TECHNICIAN
              }
            />
          </Form.Group>
          <Form.Group className="col-12 pl-0 col-md-6">
            <Form.Label>Venmo Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Venmo Username"
              value={localEmployee.venmo || ""}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  venmo: evt.currentTarget.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="col-12 pr-0 col-md-6">
            <Form.Label>Cash App Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Cash App Username"
              value={localEmployee.cashapp || ""}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  cashapp: evt.currentTarget.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="col-12 pl-0">
            <Form.Label>
              Role
              <small className="text-danger ml-1">*</small>
            </Form.Label>
            <Form.Control
              as="select"
              value={localEmployee.role}
              onChange={(evt) =>
                setLocalEmployee({
                  ...localEmployee,
                  role: evt.currentTarget.value,
                })
              }
              required={true}
            >
              <option value="">Select One</option>
              {EMPLOYEE_ROLES.map((r, i) => (
                <option key={i} value={r.value}>
                  {r.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {localEmployee.role && localEmployee.role !== USER_ROLE_TECHNICIAN ? (
            <div className="col-12 px-0 form-group">
              <span className="subtitle">App Credentials</span>
              <div className="credentials mt-2">
                <Form.Group className="col-12 p-0">
                  <Form.Label>
                    Password
                    <small className="text-danger ml-1">*</small>
                  </Form.Label>
                  <Form.Control
                    required={!employee?.id}
                    autoComplete="new-password"
                    maxLength="50"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={localEmployee.password || ""}
                    onChange={(evt) =>
                      setLocalEmployee({
                        ...localEmployee,
                        password: evt.currentTarget.value.trim(),
                      })
                    }
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          {localEmployee.role && localEmployee.role !== USER_ROLE_EXECUTIVE ? (
            <Form.Group className="col-12 pl-0">
              <Form.Label>
                Category
                <small className="text-danger ml-1">*</small>
              </Form.Label>
              <Form.Control
                as="select"
                value={localEmployee.category_id}
                onChange={(evt) =>
                  setLocalEmployee({
                    ...localEmployee,
                    category_id: evt.currentTarget.value,
                  })
                }
                required={true}
              >
                <option value="">Select One</option>
                {EMPLOYEE_CATEGORIES.map((r, i) => (
                  <option key={i} value={r.value}>
                    {r.category}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : null}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button className="bg-primary" variant="primary" type="submit">
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default withErrorHandler(EmployeeModal);
