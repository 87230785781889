import React from "react";
import { Button, Nav } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./Navigation.scss";
import { useAuth } from "./providers/authProvider";
import Api from "./api/Api";

const Navigation = ({ history }) => {
  const [authContext, setAuthContext] = useAuth();

  const links = [
    { name: "jobs", protected: true },
    { name: "payroll", protected: true },
    { name: "employees", protected: true },
    { name: "customers", protected: true },
    { name: "expenses", protected: true },
  ];

  const isActiveRoute = (route) =>
    window.location.pathname.includes(route.name);

  const login = () => history.push("/login");

  const logout = () => {
    Api.logout().then((result) => {
      setAuthContext({ currentUser: null });
      history.push("/login");
    });
  };

  const loggedIn = () => authContext.currentUser?.id;

  return (
    <div className="no-print navigation p-0">
      <div className="app-name text-left">
        <span className="font-600">Faulkner</span>
        <span> Industrial</span>
      </div>
      <Nav className="justify-content-end">
        {!loggedIn() ? (
          <>
            {links
              .filter((l) => !l.protected)
              .map((l, i) => (
                <Nav.Item key={i}>
                  <Nav.Link
                    className={isActiveRoute(l) ? "active" : ""}
                    onClick={() => history.push(`/${l.name}`)}
                  >
                    {l.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            <Nav.Item>
              <Nav.Link className="signIn">
                <Button className="btn-sm bg-primary" onClick={login}>
                  Log In
                </Button>
              </Nav.Link>
            </Nav.Item>
          </>
        ) : (
          <>
            {links.map((l, i) => (
              <Nav.Item key={i}>
                <Nav.Link
                  className={isActiveRoute(l) ? "active" : ""}
                  onClick={() => history.push(`/${l.name}`)}
                >
                  {l.name}
                </Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item>
              <Nav.Link className="signIn">
                <Button onClick={logout} className="btn-sm bg-primary">
                  Log Out
                </Button>
              </Nav.Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    </div>
  );
};

export default withRouter(Navigation);
