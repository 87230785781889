import React, { useState } from "react";
import withErrorHandler from "../../error/WithErrorHandler";
import "./EmployeePayrollModal.scss";
import moment from "moment";
import { Form, InputGroup } from "react-bootstrap";
import { getTimeAsHours } from "../../Utils/Utils";
import { ACTIONS, usePayroll } from "../../providers/payrollProvider";

const PayrollClockEntry = ({ entry, index }) => {
  const [, setPayrollContext] = usePayroll();

  const [localEntry, setLocalEntry] = useState({
    ...entry,
    lunch_break_formatted: getTimeAsHours(entry.lunch_break).toString(),
  });

  const [hasClockError, setHasClockError] = useState(false);
  const [hasLunchError, setHasLunchError] = useState(false);

  const checkLunchError = (updatedEntry) => {
    const clock_duration = moment
      .duration(
        moment(updatedEntry.clock_out_time).diff(
          moment(updatedEntry.clock_in_time)
        )
      )
      .as("minutes");
    const hasLunchError = updatedEntry.lunch_break > clock_duration;
    updatedEntry.hasLunchError = hasLunchError;
    setHasLunchError(hasLunchError);
  };

  const checkEndTimeError = (updatedEntry) => {
    const hasClockError =
      updatedEntry.clock_in_time &&
      updatedEntry.clock_out_time &&
      moment(updatedEntry.clock_out_time).isBefore(
        moment(updatedEntry.clock_in_time),
        "minutes"
      );
    updatedEntry.hasClockError = hasClockError;
    setHasClockError(hasClockError);
  };

  const onLunchTimeChanged = (e) => {
    const lunch_break = moment.duration(e.currentTarget.value).asMinutes();
    const lunch_break_formatted = getTimeAsHours(lunch_break).toString();
    const touchedClock = {
      ...localEntry,
      lunch_break,
      lunch_break_formatted,
    };
    checkLunchError(touchedClock);
    setLocalEntry(touchedClock);
    setPayrollContext({
      action: ACTIONS.CHANGE_TOUCHED_CLOCKS,
      payload: { touchedClock },
    });
  };

  const onStartTimeChanged = (e) => {
    const clock_in_time = moment(e.currentTarget.value).toISOString();
    const touchedClock = {
      ...localEntry,
      clock_in_time,
    };
    checkLunchError(touchedClock);
    checkEndTimeError(touchedClock);
    setLocalEntry(touchedClock);
    setPayrollContext({
      action: ACTIONS.CHANGE_TOUCHED_CLOCKS,
      payload: { touchedClock },
    });
  };

  const onEndTimeChanged = (e) => {
    const clock_out_time = moment(e.currentTarget.value).toISOString();
    const touchedClock = {
      ...localEntry,
      clock_out_time,
    };
    checkLunchError(touchedClock);
    checkEndTimeError(touchedClock);
    setLocalEntry(touchedClock);
    setPayrollContext({
      action: ACTIONS.CHANGE_TOUCHED_CLOCKS,
      payload: { touchedClock },
    });
  };

  return (
    <tr key={index}>
      <td className="align-middle">
        <i className="fas fa-clock text-primary fa-2x" />
      </td>
      <td>
        <Form.Control
          type="datetime-local"
          onChange={onStartTimeChanged}
          value={
            localEntry.clock_in_time
              ? moment(localEntry.clock_in_time).format("YYYY-MM-DDTHH:mm")
              : ""
          }
          required={true}
        />
      </td>
      <td>
        <Form.Control
          type="datetime-local"
          className={hasClockError ? "border-danger" : ""}
          onChange={onEndTimeChanged}
          value={
            localEntry.clock_out_time
              ? moment(localEntry.clock_out_time).format("YYYY-MM-DDTHH:mm")
              : ""
          }
          required={true}
        />
        {hasClockError ? (
          <div className="text-danger font-weight-medium small mt-1">
            End time must be after start time
          </div>
        ) : null}
      </td>
      <td>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className="fas fa-utensils text-primary"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            className={`hours-input ${hasLunchError ? "border-danger" : ""}`}
            placeholder="Set new value"
            onChange={onLunchTimeChanged}
            defaultValue={localEntry.lunch_break_formatted || ""}
            type="text"
          />
        </InputGroup>
        {hasLunchError ? (
          <div className="text-danger font-weight-medium small mt-1">
            Lunch break can't be longer than clock time
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default withErrorHandler(PayrollClockEntry);
