import React, { useEffect, useState } from "react";
import "./JobDetail.scss";
import Api from "../../api/Api";
import withErrorHandler from "../../error/WithErrorHandler";
import { Button, Spinner } from "react-bootstrap";
import JobModal from "../JobModal";
import JobDetailSidebar from "./JobDetailSidebar";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { JOB_STATUSES } from "../../constants";
import ConfirmModal from "../../Utils/ConfirmModal";
import InformationModal from "../../Utils/InformationModal";
import { ACTIONS, useJobDetail } from "../../providers/jobDetailsProvider";
import { toast } from "react-toastify";
import JobInfo from "./JobInfo";
import JobStatus from "./JobStatus";
import JobCurrentSection from "./JobCurrentSection";

const JOB_STATUS_COMPLETED = "2";
const JOB_STATUS_IN_PROGRESS = "3";

const JobDetail = ({ history, match }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();

  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState(false);
  const [jobModal, setJobModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState();
  const [reportMode, setReportMode] = useState(false);

  const [genaratePDFLoading, setGenaratePDFLoading] = useState(false);

  const generateJobPackagePDF = async () => {
    setGenaratePDFLoading(true);
    const response = await Api.getJobPackageReportPDF({
      id: match.params.jobId,
    });
    const data = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement("a");
    tempLink.href = pdfURL;
    tempLink.hidden = true;
    tempLink.setAttribute("download", `Job Package.pdf`);
    tempLink.click();
    setGenaratePDFLoading(false);
  };

  const changeStatus = async (status) => {
    setLoading(true);
    const updatedJob = {
      ...jobDetailContext.job,
      status: status.value,
    };
    try {
      await Api.putJob(updatedJob);
      setJobDetailContext({
        action: ACTIONS.SET_JOB,
        payload: {
          job: updatedJob,
        },
      });
      toast.success("Job updated successfully");
    } catch (err) {
      const msg = err.response?.data?.length
        ? err.response.data[0].msg
        : "There was an error with your request";
      toast.error(msg);
    }
    setLoading(false);
  };

  const confirmCloseJob = async () => {
    jobDetailContext.job.status = JOB_STATUS_COMPLETED;
    setLoading(true);
    try {
      await Api.putJob(jobDetailContext.job);
      toast.success("Job updated successfully.");
      setConfirmationModal(false);
    } catch (err) {
      const msg = err.response?.data?.length
        ? err.response.data[0].msg
        : "There was an error with your request";
      toast.error(msg);
    }
    setLoading(false);
  };

  const submitJob = async (data) => {
    setLoading(true);
    const job = {
      ...jobDetailContext.job,
      ...data,
    };
    try {
      await Api.putJob(job);
      setJobModal(false);
      setRefresh(!refresh);
      toast.success("Job updated successfully");
    } catch (err) {
      const msg = err.response?.data?.length
        ? err.response.data[0].msg
        : "There was an error with your request";
      toast.error(msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await Api.getJobs({ id: match.params.jobId });
        if (!result.data.jobs.length) {
          return history.push("/jobs");
        }
        const job = result.data.jobs[0];
        setJobDetailContext({
          action: ACTIONS.SET_JOB,
          payload: {
            job,
          },
        });
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
      setLoading(false);
    };
    fetchData();
  }, [history, match, refresh, setJobDetailContext]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await Api.getJobDays(match.params.jobId);
        const jobDays = res.data.jobDays.sort(
          (x, y) => new Date(x.date).getTime() - new Date(y.date).getTime()
        );
        setJobDetailContext({
          action: ACTIONS.SET_JOB_DAYS,
          payload: {
            jobDays,
          },
        });
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
      setLoading(false);
    };
    fetchData();
  }, [match.params.jobId, setJobDetailContext, history, match, refresh]);

  useEffect(() => {
    if (reportMode) {
      const checkLoadedImages = (loops, callback) => {
        const images = document.getElementsByTagName("img");
        let success = true;
        let i = 0;
        if (!images.length) {
          return callback(success);
        }
        while (success && loops < 10) {
          success = images[i].complete;
          i = (i + 1) % images.length;
          loops = loops + 1;
        }
        return success || loops >= 10
          ? callback(success)
          : setTimeout(() => checkLoadedImages(loops, callback), 500);
      };
      checkLoadedImages(0, (success) => {
        console.log(success ? "All images loaded" : "Error loading images");
        window.print();
        setReportMode(false);
      });
    }
  }, [reportMode]);

  const getJobStatusValue = () => {
    const jobStatus = JOB_STATUSES.find(
      (s) => s.value === jobDetailContext.job?.status
    );
    return jobStatus
      ? {
          key: jobStatus.value,
          label: jobStatus.name,
          value: jobStatus.value,
        }
      : null;
  };

  return (
    <div>
      {!jobDetailContext.job || !jobDetailContext.jobDays || loading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <div className="job-detail p-0">
          <div className="col-12 p-0 d-flex align-items-center justify-content-between">
            <div className="status-container">
              {reportMode ? null : (
                <Button
                  onClick={() => history.push("/jobs")}
                  className="bg-primary font-weight-medium mr-3"
                >
                  <i className="fas fa-chevron-left"></i>
                  Jobs
                </Button>
              )}
              <h6 className="mb-0 mr-3">
                {`${
                  jobDetailContext.job.so_number
                    ? jobDetailContext.job.so_number + " / "
                    : ""
                }${jobDetailContext.job.location}`}
              </h6>
              {reportMode ? (
                <JobStatus job={jobDetailContext.job} />
              ) : (
                <Select
                  name="status"
                  onChange={changeStatus}
                  value={getJobStatusValue()}
                  classNamePrefix="select"
                  className="basic-select status"
                  options={JOB_STATUSES.map((s) => ({
                    key: s.value,
                    label: s.name,
                    value: s.value,
                  }))}
                />
              )}
              {reportMode ? null : (
                <span onClick={() => setJobModal(true)}>
                  <i className="fas fa-pencil-alt text-primary cursor-pointer"></i>
                </span>
              )}
            </div>
            <div>
              {genaratePDFLoading ? (
                <Spinner
                  className="mr-5"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Button
                  onClick={() => generateJobPackagePDF()}
                  className="no-print bg-primary font-weight-medium"
                >
                  Job Package
                </Button>
              )}
              {jobDetailContext.job.status === JOB_STATUS_IN_PROGRESS ? (
                <Button
                  onClick={() => setConfirmationModal(true)}
                  className="no-print btn-danger font-weight-medium ml-1"
                >
                  Close Job
                </Button>
              ) : null}
            </div>
          </div>
          <div className="col-12 p-0 d-flex align-items-center justify-content-start mb-4 mt-2">
            <span className="font-weight-medium">Job Lead Foreman:</span>{" "}
            <span>
              {jobDetailContext.job.leadForeman
                ? `${jobDetailContext.job.leadForeman.firstName} ${jobDetailContext.job.leadForeman.lastName}`
                : "N/A"}
            </span>
          </div>
          <div className="d-flex">
            <div className="job-description-container col-6 d-flex flex-column mb-3 p-2 mr-1 justify-content-between rounded">
              <span className="label">Job Scope</span>
              <span className="text-left">
                {jobDetailContext.job.description}
              </span>
            </div>
            <div className="job-internal-note-container col-6 d-flex flex-column mb-3 p-2 ml-1 justify-content-between rounded">
              <span className="label">Internal Note</span>
              <span className="text-left">
                {jobDetailContext.job.internal_note}
              </span>
            </div>
          </div>
          <div className="info-container col-12 p-0">
            <JobInfo />
          </div>
          <div className="d-flex">
            {reportMode ? null : (
              <div className="mr-2">
                <JobDetailSidebar />
              </div>
            )}
            <div className="mt-4 ml-2 flex-grow-1">
              <JobCurrentSection
                doRefresh={() => setRefresh(!refresh)}
                reportMode={reportMode}
              />
            </div>
          </div>
          {jobModal ? (
            <JobModal
              submitButtonLabel={"Save"}
              onSubmit={submitJob}
              onClose={() => setJobModal(false)}
              showJobLeadForeman={true}
            />
          ) : null}
          {confirmationModal ? (
            <ConfirmModal
              submit={confirmCloseJob}
              onClose={() => setConfirmationModal(false)}
              variant="danger"
              body={`Are you sure you want to close the job?`}
              title="Close Job"
            />
          ) : null}
          {informationModal.isOpen ? (
            <InformationModal
              onClose={() =>
                setInformationModal({ isOpen: false, title: "", body: "" })
              }
              body={informationModal.body}
              title={informationModal.title}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default withErrorHandler(withRouter(JobDetail));
