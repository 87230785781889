import React from "react";
import withErrorHandler from "../../error/WithErrorHandler";
import JobDetailHomeSection from "./Sections/JobDetailHomeSection";
import JobDetailChangeOrderSection from "./Sections/JobDetailChangeOrderSection";
import JobDetailTimeSection from "./Sections/JobDetailTimeSection";
import JobDetailExpensesSection from "./Sections/JobDetailExpensesSection";
import JobDetailBackOfficeNotesSection from "./Sections/JobDetailBackOfficeNotesSection";
import JobDetailCrewNotesSection from "./Sections/JobDetailCrewNotesSection";
import { withRouter } from "react-router-dom";
import JobDetailCrewsSection from "./Sections/JobDetailCrewsSection";
import { useJobDetail } from "../../providers/jobDetailsProvider";

const SECTION_HOME = 1;
const SECTION_CHANGE_ORDER = 2;
const SECTION_TIME = 3;
const SECTION_EXPENSES = 4;
const SECTION_CREWS = 5;
const SECTION_BACK_OFFICE_NOTES = 6;
const SECTION_CREW_NOTES = 7;

const JobCurrentSection = ({ doRefresh, reportMode }) => {
  const [jobDetailContext] = useJobDetail();

  if (reportMode) {
    return (
      <>
        <h5 className="mt-0">Job Overview</h5>
        <JobDetailHomeSection reportMode={reportMode} />
        <h5 className="mb-0">Work Orders</h5>
        <JobDetailChangeOrderSection />
        <JobDetailTimeSection reportMode={reportMode} />
        <JobDetailExpensesSection reportMode={reportMode} />
      </>
    );
  }

  switch (jobDetailContext.currentSection) {
    case SECTION_HOME:
      return <JobDetailHomeSection />;
    case SECTION_CHANGE_ORDER:
      return <JobDetailChangeOrderSection />;
    case SECTION_TIME:
      return <JobDetailTimeSection />;
    case SECTION_EXPENSES:
      return <JobDetailExpensesSection />;
    case SECTION_CREWS:
      return <JobDetailCrewsSection doRefresh={doRefresh} />;
    case SECTION_CREW_NOTES:
      return <JobDetailCrewNotesSection />;
    case SECTION_BACK_OFFICE_NOTES:
      return <JobDetailBackOfficeNotesSection doRefresh={doRefresh} />;
    default:
      return <></>;
  }
};

export default withErrorHandler(withRouter(JobCurrentSection));
