import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import withErrorHandler from "../../../error/WithErrorHandler";

import { faUpload, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./BackOfficeNoteCreateEditModal.scss";

const FILE_TYPES = "application/pdf, image/*";

const BackOfficeNoteCreateEditModal = ({ onClose, onSubmit, note }) => {
  const hiddenFileInput = useRef(null);
  const [noteText, setNoteText] = useState(note ? note.note : "");
  const [attachmentsInfo, setAttachmentsInfo] = useState([]);

  useEffect(() => {
    setAttachmentsInfo(
      note
        ? note.files.map((n) => {
            if (n.file_url) {
              const splitUrl = n.file_url.split("/");
              const name = splitUrl[splitUrl.length - 1];
              n.name = name;
              return n;
            } else {
              return n;
            }
          })
        : []
    );
  }, [note]);

  const handleClickInputAttachments = (event) => {
    hiddenFileInput.current.click();
  };

  const removeAttachment = (name) => {
    setAttachmentsInfo(attachmentsInfo.filter((att) => att.name !== name));
  };

  const loadAttachments = (event) => {
    const files = event.target.files;
    const mappedFiles = [];
    for (let i = 0; i < files.length; i++) {
      mappedFiles.push({
        id: null,
        name: files[i].name,
        type: files[i].type,
        file: files[i],
      });
    }
    const infos = mapFilesToAttachmentInfo(mappedFiles);
    setAttachmentsInfo(mergeInfosUnique(attachmentsInfo, infos));
    event.target.value = null;
  };

  const mapFilesToAttachmentInfo = (files) => {
    const infos = [];
    for (let i = 0; i < files.length; i++) {
      infos.push({
        id: files[i].id,
        name: files[i].name,
        type: files[i].type,
        file: files[i].file,
      });
    }
    return infos;
  };

  const mergeInfosUnique = (arr1, arr2) => {
    return arr1.concat(
      arr2.filter((item2) => {
        return !arr1.find((item1) => item1.name === item2.name);
      })
    );
  };

  return (
    <Modal className="job-modal" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {note ? "Edit Back office notes" : "Add Back office notes"}
        </Modal.Title>
      </Modal.Header>
      <Form
        className="crew-form"
        onSubmit={(event) => onSubmit(event, noteText, attachmentsInfo)}
      >
        <Modal.Header>
          <Form.Label>
            <span>Back Office Note</span>
            <small className="text-danger ml-1">*</small>
            <span className="text-length text-primary ml-1">{`(${noteText.length}/255)`}</span>
          </Form.Label>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center py-3 border-bottom border-top">
          <div className="px-0 col-12 d-flex flex-column py-2">
            <Form.Group className="col-12 pl-0 d-flex form-group align-items-center">
              <Form.Control
                required={true}
                type="textarea"
                as="textarea"
                rows="3"
                maxLength="255"
                placeholder="Add your notes here.."
                value={noteText}
                data-value="notes"
                onChange={(evt) => {
                  setNoteText(evt.currentTarget.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label sm={3} className="text-sm-left">
                Files
              </Form.Label>
              <div
                className={`${
                  attachmentsInfo.length === 0
                    ? "d-flex align-items-center"
                    : ""
                }`}
              >
                <input
                  ref={hiddenFileInput}
                  value=""
                  multiple={true}
                  type="file"
                  accept={FILE_TYPES}
                  className="mb-1 text-truncate"
                  onChange={loadAttachments}
                  style={{ display: "none" }}
                />

                <Button
                  size="sm"
                  className="rounded mr-2"
                  onClick={handleClickInputAttachments}
                >
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  Choose Files
                </Button>

                {attachmentsInfo.length > 0 ? (
                  <div className="d-flex flex-wrap mt-3">
                    {attachmentsInfo.map((att) => {
                      return (
                        <div
                          key={att.name}
                          className="badge bg-secondary d-flex text-white ml-0"
                        >
                          <span className="text-truncate">{att.name}</span>
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="ml-2 m-top cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeAttachment(att.name);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span>No files attached</span>
                )}
              </div>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex col-12 justify-content-between">
          <Button variant={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!noteText.length}
            className={"bg-primary"}
            variant={"primary"}
            type={"submit"}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default withErrorHandler(BackOfficeNoteCreateEditModal);
