import React, { useCallback, useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Row, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import { useHistory, withRouter } from "react-router-dom";
import "./CustomersList.scss";
import CustomerModal from "./CustomerModal";
import { CUSTOMER_STATUSES } from "../constants";
import { toast } from "react-toastify";
import CustomerActions from "./CustomerActions";
import { RefreshCw } from "react-feather";

const PAGE_SIZE = 10;

const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

const CustomersList = () => {
  const history = useHistory();
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState(SORT_ORDER.ASC);
  const [data, setData] = useState({
    customers: [],
    pagination: {
      page: 1,
      pageCount: 0,
      pageSize: PAGE_SIZE,
      rowCount: 0,
    },
  });
  const [refresh, setRefresh] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [search, setSearch] = useState("");

  const onSort = (sortByValue, directionValue) => {
    setSortBy(sortByValue);
    setDirection(directionValue);
  };

  const TABLE_COLUMNS = (onSort) => [
    {
      dataField: "name",
      text: "Name",
      classes: "text-truncate align-middle",
      headerStyle: { width: "35%" },
      sort: true,
      formatter: (cell) => cell || "-",
      onSort,
    },
    {
      dataField: "email",
      text: "Email",
      classes: "text-truncate align-middle",
      headerStyle: { width: "20%" },
      formatter: (cell) => cell || "-",
      sort: true,
      onSort,
    },
    {
      dataField: "contact_name",
      text: "Contact Name",
      classes: "text-truncate align-middle",
      headerStyle: { width: "25%" },
      formatter: (cell) => cell || "-",
      sort: true,
      onSort,
    },
    {
      dataField: "active",
      text: "",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      headerFormatter: () => (
        <div role="columnheader">
          <select
            className="status-select font-weight-medium"
            onChange={filterStatusChange}
            value={selectedStatus}
          >
            <option value="">Status</option>
            {CUSTOMER_STATUSES.map((s, i) => (
              <option key={i} value={s.value}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      ),
      formatter: (cell) => (
        <span className={`${cell ? "active" : "inactive"} badge p-2`}>
          {<i className="fas fa-circle mr-2"></i>}
          {cell ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];

  const fetchCustomers = useCallback(() => {
    setLoading(true);
    // const { pagination } = data;
    Api.getCustomers({
      page: data.pagination.page,
      pageSize: data.pagination.pageSize,
      search,
      status: selectedStatus,
      sortBy,
      direction,
    })
      .then((res) => {
        setLoading(false);
        setData({
          ...res.data,
        });
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  }, [
    search,
    selectedStatus,
    sortBy,
    direction,
    data.pagination.page,
    data.pagination.pageSize,
  ]);

  useEffect(() => {
    fetchCustomers();
  }, [
    data.pagination.page,
    data.pagination.pageSize,
    sortBy,
    direction,
    refresh,
    fetchCustomers,
  ]);

  const searchChange = (evt) => {
    setSearch(evt.currentTarget.value);
    setData({
      customers: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const newCustomerClick = () => setShowCustomerModal(true);

  const onModalClose = () => setShowCustomerModal(false);

  const submitCustomer = (data) => {
    setLoading(true);
    Api.postCustomer(data)
      .then(() => {
        toast.success("Customer created successfully.");
        setRefresh(!refresh);
        setShowCustomerModal(false);
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const filterStatusChange = (evt) => {
    setSelectedStatus(evt.currentTarget.value);
    setData({
      ...data,
      customer: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const onRefresh = () => setRefresh(!refresh);

  const { customers, pagination } = data;

  return (
    <div className="customers-list">
      <Row className="controls-container pt-1">
        <h4 className="text-left float-left customers-title">
          Customers List ({pagination.rowCount})
        </h4>
        <InputGroup className="col-12 col-md-6 col-lg-3 customers-search">
          <FormControl
            placeholder="Search"
            aria-label="Search Customer Name"
            onChange={searchChange}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <i className="fas fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <div className="d-flex flex-grow-1 justify-content-end pr-4 pt-1">
          <Button
            onClick={() => newCustomerClick()}
            variant="primary"
            className="bg-primary d-flex align-items-center rounded-circle"
          >
            <i className="fas fa-plus fa-sm"></i>
          </Button>
          <Button
            size="sm"
            className="rounded-circle d-flex align-items-center custom-rounded-button-refresh ml-2"
            color="primary"
            onClick={() => onRefresh()}
          >
            <RefreshCw size={18} />
          </Button>
        </div>
      </Row>
      <div className="text-left table-container col-12" role="table">
        {loading ? (
          <div className="row-container">
            <div className="flex-table text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          </div>
        ) : (
          <BootstrapTable
            bootstrap4
            remote
            bordered={false}
            keyField="id"
            headerClasses="bg-light"
            data={customers}
            classes="cursor-pointer"
            noDataIndication={() => "No data to show"}
            columns={[
              ...TABLE_COLUMNS(onSort),
              {
                dataField: "",
                text: "",
                sort: false,
                classes: "text-right",
                formatter: (cell, customer) => (
                  <CustomerActions customer={customer} onRefresh={onRefresh} />
                ),
              },
            ]}
            rowEvents={{
              onClick: (_, customer) =>
                history.push(`/customers/${customer.id}`),
            }}
            defaultSorted={[
              {
                dataField: sortBy,
                order: direction,
              },
            ]}
            onTableChange={() => {}}
            pagination={paginationFactory({
              page: pagination.page,
              totalSize: pagination.rowCount,
              sizePerPage: pagination.pageSize,
              sizePerPageList: [
                5,
                10,
                25,
                50,
                { text: "All", value: pagination.rowCount },
              ],
              onPageChange: (page) =>
                setData({
                  ...data,
                  pagination: {
                    ...data.pagination,
                    page: page,
                  },
                }),
              onSizePerPageChange: (sizePerPage) =>
                setData({
                  ...data,
                  pagination: {
                    ...data.pagination,
                    pageSize: sizePerPage,
                    page: 1,
                  },
                }),
            })}
          />
        )}
      </div>
      {showCustomerModal ? (
        <CustomerModal
          loading={loading}
          submit={submitCustomer}
          onClose={onModalClose}
        />
      ) : null}
    </div>
  );
};

export default withErrorHandler(withRouter(CustomersList));
