import React from "react";
import withErrorHandler from "../error/WithErrorHandler";
import { Modal, Button } from "react-bootstrap";

const InformationModal = ({ title, body, onClose }) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withErrorHandler(InformationModal);
