import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

Sentry.init({
  dsn: "https://d5f9fe0fc5ae4166a3068511c95aff8c@o912544.ingest.sentry.io/6308132",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.origin],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "local",
});

ReactDOM.render(<App />, document.getElementById("root"));
