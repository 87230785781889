import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import withErrorHandler from "../../../error/WithErrorHandler";
import JobCrew from "../../JobCrew";

const CrewCreationModal = ({ onClose, onSubmit }) => {
  const [crew, setCrew] = useState({
    technicians: [],
    foremans: [],
    crew_size: 1,
  });

  const doSubmit = () => {
    if (!crew.foremans.length) {
      return toast.error("You must add at least one foreman");
    }
    onSubmit(crew);
  };

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Crew</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <JobCrew crew={crew} setCrew={setCrew} />
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-between">
        <Button variant="secondary" onClick={onClose}>
          Discard
        </Button>
        <Button variant="primary" onClick={doSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withErrorHandler(CrewCreationModal);
