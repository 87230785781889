import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import { parseEmployeeSelect } from "../Utils/Utils";

const ROLE_TECHNICIAN = "1";
const ROLE_FOREMAN = "2";
const ROLE_EXECUTIVE = "3";

const multiSelectStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#3D7FE5",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#333",
    padding: "5px",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#3D7FE5",
      color: "white",
    },
  }),
};

const JobCrew = ({
  crew,
  setCrew,
  onSubmit,
  onRemove,
  assignedEmployees = [],
}) => {
  const [loading, setLoading] = useState();
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await Api.getEmployees({ assignable: true });
        setEmployees(result.data.employees);
        setLoading(false);
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
    };
    fetchData();
  }, []);

  const doSubmit = () => {
    if (!crew.foremans.length) {
      return toast.error("You must add at least one foreman");
    }
    onSubmit(crew);
  };

  const leadForemanSelectChanged = (selected) => {
    const foremans = [...crew.foremans];
    const fIndex = foremans.findIndex((f) => f.is_lead);
    if (fIndex > -1) {
      foremans.splice(fIndex, 1);
    }
    if (selected) {
      const employeeCrew = {
        ...selected,
        is_lead: true,
        employee_id: selected.value,
        crew_id: crew.id,
        employee: {
          id: selected.value,
          name: selected.label,
        },
        role: ROLE_FOREMAN,
      };
      foremans.push(employeeCrew);
    }
    if (foremans.length + crew.technicians.length > crew.crew_size) {
      return toast.error("Crew size exceeded.");
    }
    setCrew({
      ...crew,
      foremans,
    });
  };

  const foremanSelectChanged = (selected) => {
    const foremans = [...crew.foremans.filter((f) => f.is_lead)];
    if (selected) {
      const employees = selected.length ? [...selected] : [selected];
      employees.forEach((current) => {
        const fIndex = foremans.findIndex(
          (f) => f.employee_id === current.value
        );
        if (fIndex > -1) {
          foremans.splice(fIndex, 1);
        }
        const employeeCrew = {
          ...current,
          is_lead: false,
          employee_id: current.value,
          crew_id: crew.id,
          employee: {
            id: current.value,
            name: current.label,
          },
          role: ROLE_FOREMAN,
        };
        foremans.push(employeeCrew);
      });
    }
    if (foremans.length + crew.technicians.length > crew.crew_size) {
      return toast.error("Crew size exceeded.");
    }
    setCrew({
      ...crew,
      foremans,
    });
  };

  const technicianSelectChanged = (selected) => {
    const technicians = [];
    if (selected) {
      const employees = selected.length ? [...selected] : [selected];
      employees.forEach((current) => {
        const tIndex = technicians.findIndex(
          (t) => t.employee_id === current.value
        );
        if (tIndex > -1) {
          technicians.splice(tIndex, 1);
        }
        const employeeCrew = {
          ...current,
          employee_id: current.value,
          crew_id: crew.id,
          employee: {
            id: current.value,
            name: current.label,
          },
          role: ROLE_TECHNICIAN,
        };
        employeeCrew.role = ROLE_TECHNICIAN;
        technicians.push(employeeCrew);
      });
    }
    if (crew.foremans.length + technicians.length > crew.crew_size) {
      return toast.error("Crew size exceeded.");
    }
    setCrew({
      ...crew,
      technicians,
    });
  };

  const isEmployeeMemberOfCrew = (employeeId) =>
    [...crew.foremans, ...crew.technicians].some(
      (e) => e.employee_id === employeeId
    );

  const getSelectOptions = (role) => {
    return employees
      .filter(
        (e) =>
          (e.role === role || e.role === ROLE_EXECUTIVE) &&
          e.active &&
          !assignedEmployees.find((a) => a === e.id) &&
          !isEmployeeMemberOfCrew(e.id)
      )
      .map((c) => parseEmployeeSelect(c));
  };

  return (
    <Col>
      {loading ? (
        <div className="d-flex justify-content-center mb-n1">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="text-primary"
          />
        </div>
      ) : (
        <>
          <Row className="d-flex justify-content-start">
            <Col className="col-6 d-flex flex-column pl-0">
              <span className="font-weight-medium text-left">
                Lead Foreman:
                <small className="text-danger ml-1">*</small>
              </span>
              <Select
                required={true}
                isMulti={false}
                name="lead-foreman"
                value={crew.foremans
                  .filter((f) => f.is_lead)
                  .map((c) => c.employee)
                  .map((c) => parseEmployeeSelect(c))}
                className="basic-multi-select flex-grow-1 mt-2"
                options={getSelectOptions(ROLE_FOREMAN)}
                classNamePrefix="select"
                onChange={(values) => leadForemanSelectChanged(values)}
                styles={multiSelectStyles}
              />
            </Col>
            <Col className="col-6 d-flex flex-column pr-0">
              <span className="font-weight-medium text-left">
                Other Foremen:
              </span>
              <Select
                required={true}
                isMulti={true}
                name="foremans"
                value={crew.foremans
                  .filter((f) => !f.is_lead)
                  .map((c) => c.employee)
                  .map((c) => parseEmployeeSelect(c))}
                className="basic-multi-select flex-grow-1 mt-2"
                options={getSelectOptions(ROLE_FOREMAN)}
                classNamePrefix="select"
                onChange={(values) => foremanSelectChanged(values)}
                styles={multiSelectStyles}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-start mt-3">
            <Col className="col-6 d-flex flex-column pl-0">
              <span className="font-weight-medium text-left">
                Crew Members:
              </span>
              <Select
                required={true}
                isMulti
                name="technicians"
                value={crew.technicians
                  .map((c) => c.employee)
                  .map((c) => parseEmployeeSelect(c))}
                className="basic-multi-select flex-grow-1 mt-2"
                options={getSelectOptions(ROLE_TECHNICIAN)}
                classNamePrefix="select"
                onChange={(values) => technicianSelectChanged(values)}
                styles={multiSelectStyles}
              />
            </Col>
            <Col className="col-6 d-flex align-items-end justify-content-between pr-0">
              <div className="d-flex flex-column flex-grow-1">
                <span className="font-weight-medium text-left">Crew Size:</span>
                <InputGroup className="col-12 px-0 mt-2">
                  <FormControl
                    min={crew.foremans.length + crew.technicians.length}
                    type={"number"}
                    placeholder="Crew Size"
                    value={crew.crew_size}
                    onChange={(event) => {
                      const crewSize = event.currentTarget.value;
                      const addedCrewSize =
                        crew.foremans.length + crew.technicians.length;
                      if (addedCrewSize > event.currentTarget.value) {
                        return toast.error("Crew size exceeded");
                      }
                      setCrew({
                        ...crew,
                        crew_size: crewSize,
                      });
                    }}
                  />
                </InputGroup>
              </div>
              <div>
                {onSubmit ? (
                  <Button
                    size="sm"
                    variant="warning"
                    className="flex-shrink-0 ml-4 mb-1 text-white"
                    onClick={doSubmit}
                  >
                    Save
                  </Button>
                ) : null}
                {onRemove ? (
                  <Button
                    variant="danger"
                    size="sm"
                    className="flex-shrink-0 ml-2 mb-1"
                    onClick={() => onRemove(crew)}
                  >
                    Remove
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
};

export default withErrorHandler(JobCrew);
