import React, { useState } from "react";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import { useAuth } from "../providers/authProvider";
import ConfirmModal from "../Utils/ConfirmModal";
import EmployeeModal from "./EmployeeModal";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

const ADMIN_ROLE = "3";

const EmployeeActions = ({ employee, onRefresh }) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);

  const isAdminUser = () => authContext.currentUser?.role === ADMIN_ROLE;

  const activeInactiveEmployeeSubmit = () => {
    setLoading(true);
    return Api.putEmployee({
      ...employee,
      active: !employee.active,
    })
      .then(() => {
        toast.success("Employee updated successfully.");
        setLoading(false);
        setShowActiveModal(false);
        onRefresh();
      })
      .catch((err) => {
        setLoading(false);
        setShowActiveModal(false);
        onRefresh();
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      });
  };

  const onDeleteEmployee = () => {
    if (authContext.currentUser.id === employee.id) {
      return toast.error("Can't delete youself");
    }
    setShowDeleteModal(true);
  };

  const deleteEmployee = () => {
    setLoading(true);
    Api.deleteEmployee(employee.id)
      .then(() => {
        setLoading(false);
        setShowDeleteModal(false);
        onRefresh();
        toast.success("Employee deleted successfully");
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const submitEmployee = (data) => {
    setLoading(true);
    Api.putEmployee(data)
      .then(() => {
        toast.success("Employee updated successfully.");
        setShowEmployeeModal(false);
        setLoading(false);
        onRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      });
    setShowEmployeeModal(false);
    setLoading(false);
    onRefresh();
  };

  const onModalClose = () => {
    setShowEmployeeModal(false);
    setShowDeleteModal(false);
    setShowActiveModal(false);
  };

  return (
    <div
      onClick={(evt) => {
        evt.stopPropagation();
      }}
      className="text-center d-flex"
    >
      <Button
        variant="warning"
        size="sm"
        className="text-white"
        onClick={(evt) => {
          evt.stopPropagation();
          setShowEmployeeModal(true);
        }}
      >
        <i className="fas fa-pen"></i>
      </Button>
      {isAdminUser() ? (
        <Button
          variant="dark"
          size="sm"
          className="text-white mx-2"
          onClick={(evt) => {
            evt.stopPropagation();
            setShowActiveModal(true);
          }}
        >
          <i className="fas fa-user-lock"></i>
        </Button>
      ) : null}
      <Button
        variant="danger"
        size="sm"
        className="text-white"
        onClick={(evt) => {
          evt.stopPropagation();
          onDeleteEmployee();
        }}
      >
        <i className="fas fa-trash"></i>
      </Button>
      {showDeleteModal ? (
        <ConfirmModal
          loading={loading}
          submit={deleteEmployee}
          onClose={onModalClose}
          variant="danger"
          body={`Are you sure you want to delete ${employee.firstName} ${employee.lastName}?`}
          title="Delete Employee"
        />
      ) : null}
      {showActiveModal ? (
        <ConfirmModal
          loading={loading}
          submit={activeInactiveEmployeeSubmit}
          onClose={onModalClose}
          variant="danger"
          body={`Are you sure you want to ${
            employee.active ? "deactivate" : "activate"
          } ${employee.firstName} ${employee.lastName}?`}
          title={`${employee.active ? "Deactivate" : "Activate"} Employee`}
        />
      ) : null}
      {showEmployeeModal ? (
        <EmployeeModal
          loading={loading}
          submit={submitEmployee}
          onClose={onModalClose}
          employee={employee}
        />
      ) : null}
    </div>
  );
};

export default withErrorHandler(EmployeeActions);
