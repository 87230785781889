import React, { useState } from "react";
import withErrorHandler from "../../../error/WithErrorHandler";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { downloadFile } from "../../../Utils/Utils";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { ACTIONS, useJobDetail } from "../../../providers/jobDetailsProvider";

const TYPE_EXPENSE_PER_DIEM = 2;

const ACCORDION_KEY = "jobDetailExpensesAccordion";

const JobDetailExpensesPerDiem = ({ reportMode, type }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();
  const [showPhotoModal, setShowPhotoModal] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState({});

  const getTotal = (jd) =>
    jd.transactions
      .filter((t) => t.type === type)
      .reduce((p, c) => p + c.amount, 0);

  const handlePhotoModalClose = () => {
    setShowPhotoModal();
    setSelectedPhoto({});
  };

  const handlePhotoClick = (url, label) => {
    setShowPhotoModal(true);
    setSelectedPhoto({ url, label });
  };

  return jobDetailContext.jobDays.length ? (
    <>
      <Accordion
        className="job-detail-expenses"
        allowZeroExpanded={true}
        allowMultipleExpanded={true}
        onChange={(preExpanded) => {
          setJobDetailContext({
            action: ACTIONS.TOGGLE_ACCORDION,
            payload: {
              accordion: ACCORDION_KEY,
              preExpanded,
            },
          });
        }}
        preExpanded={
          reportMode
            ? jobDetailContext.jobDays.map((jd) => jd.id)
            : jobDetailContext[ACCORDION_KEY]
        }
      >
        {jobDetailContext.jobDays.map((jd) => (
          <AccordionItem key={jd.id} uuid={jd.id}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>{moment(jd.date).format("MMMM DD - YYYY")}</span>
                <i className="fas fa-chevron-up float-right"></i>
                <span className="float-right day-expenses">
                  $ {getTotal(jd).toFixed(2)}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {!jd.employeeDays.length ? (
                <div className="inner-section">
                  <span>No per diem recorded</span>
                </div>
              ) : (
                jd.employeeDays.map((ed) => {
                  const per_diem = jd.transactions.find(
                    (t) =>
                      parseInt(t.type) === parseInt(TYPE_EXPENSE_PER_DIEM) &&
                      t.employee_id === ed.employee_id
                  );
                  return (
                    <div key={ed.id} className="inner-section px-4">
                      <div className="d-flex flex-column">
                        <span className="employee-name">
                          {ed.employee
                            ? `${ed.employee.firstName} ${ed.employee.lastName}`
                            : "N/A"}
                        </span>
                        {per_diem?.url ? (
                          <div
                            className="figure img-thumbnail cursor-pointer mx-1"
                            onClick={() =>
                              handlePhotoClick(
                                per_diem.url,
                                per_diem.description
                              )
                            }
                            style={{
                              backgroundPosition: "start",
                              backgroundImage: `url(${per_diem.url})`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              cursor: "pointer",
                              alignSelf: "center",
                              height: reportMode ? "300px" : "100px",
                              width: reportMode ? "300px" : "100px",
                            }}
                          ></div>
                        ) : null}
                      </div>
                      <div>
                        {per_diem ? (
                          <span className="negative">-${per_diem.amount}</span>
                        ) : (
                          <span className="text-primary">Not Issued</span>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {showPhotoModal ? (
        <Modal show={true} onHide={handlePhotoModalClose}>
          <Modal.Header
            className="font-weight-medium header-photo-modal"
            closeButton
          >
            <span>{selectedPhoto.label}</span>
            <i
              className="fas fa-download"
              onClick={() => downloadFile(selectedPhoto.url)}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <img alt={""} className="modal-photo-img" src={selectedPhoto.url} />
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  ) : (
    <div className="flex-table row no-data-row" role="rowgroup">
      <div className="flex-row" role="cell">
        No per diem recorded
      </div>
    </div>
  );
};

export default withErrorHandler(JobDetailExpensesPerDiem);
