import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import withErrorHandler from "../error/WithErrorHandler";
import { useJobDetail } from "../providers/jobDetailsProvider";
import "./JobModal.scss";
import JobCrew from "./JobCrew";
import JobStatus from "./Detail/JobStatus";
import { toast } from "react-toastify";

const INIT_CREW = [
  {
    lead: false,
    crew_size: 1,
    foremans: [],
    technicians: [],
  },
];

const CrewSelectionModal = ({ onClose, onBack, onSubmit }) => {
  const [jobDetailContext] = useJobDetail();

  const [crews, setCrews] = useState(INIT_CREW);

  const [leadId, setLeadId] = useState();
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [selectJobLeadForemanOptions, setSelectJobLeadForemanOptions] =
    useState([]);
  const [leadValue, setLeadValue] = useState();

  useEffect(() => {
    setLeadId(jobDetailContext.job.lead_id);
  }, [jobDetailContext.job.lead_id]);

  const onNewCrew = () => {
    setCrews([
      ...crews,
      {
        lead: false,
        crew_size: 1,
        foremans: [],
        technicians: [],
      },
    ]);
  };

  const removeCrew = (i) => {
    const data = [...crews];
    data.splice(i, 1);
    setCrews(data);
  };

  const doSubmit = (event) => {
    event.preventDefault();
    if (!leadValue) {
      return toast.error("Please select a Job Lead Foreman");
    }
    onSubmit(crews, leadId);
  };

  useEffect(() => {
    if (jobDetailContext.job?.crews?.length) {
      setCrews([...jobDetailContext.job.crews]);
    } else {
      setCrews(INIT_CREW);
    }
  }, [jobDetailContext.job]);

  useEffect(() => {
    const assignedEmployees = crews.flatMap((crew) =>
      [...crew.foremans, ...crew.technicians].map((e) => e.employee_id)
    );
    setAssignedEmployees(assignedEmployees);
  }, [crews]);

  useEffect(() => {
    if (leadId) {
      const allForemans = (crews || []).flatMap((crew) => [...crew.foremans]);
      const leadForeman = allForemans.find((c) => c.employee_id === leadId);
      if (leadForeman) {
        const leadValue = {
          label:
            leadForeman.employee.name ||
            `${leadForeman.employee.firstName} ${leadForeman.employee.lastName}`,
          value: leadId,
        };
        setLeadValue(leadValue);
      } else {
        setLeadValue();
      }
    }
  }, [crews, leadId]);

  useEffect(() => {
    const options = (crews || [])
      .map((c) => {
        const leadForeman = c.foremans.find((f) => f.is_lead);
        return leadForeman
          ? {
              key: leadForeman.employee_id,
              label:
                leadForeman.employee.name ||
                `${leadForeman.employee.firstName} ${leadForeman.employee.lastName}`,
              value: leadForeman.employee_id,
              role: leadForeman.role,
            }
          : false;
      })
      .filter(Boolean);
    setSelectJobLeadForemanOptions(options);
  }, [crews]);

  return (
    <Modal size="lg" className="job-modal" show={true} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {jobDetailContext.job?.id ? "Edit Job" : "Create Job"}
        </Modal.Title>
        <JobStatus job={jobDetailContext.job} />
      </Modal.Header>
      <Form className="crew-form" onSubmit={doSubmit}>
        <Modal.Body className="d-flex flex-column align-items-center py-3 border-bottom border-top">
          {crews.map((crew, i) => (
            <div
              key={i}
              className="px-0 col-12 d-flex flex-column pb-4 mb-3 border-bottom align-items-center"
            >
              <JobCrew
                crew={crew}
                setCrew={(crew) => {
                  const updatedCrews = [...crews];
                  updatedCrews.splice(i, 1, crew);
                  setCrews(updatedCrews);
                }}
                onRemove={i > 0 ? () => removeCrew(i) : null}
                assignedEmployees={assignedEmployees}
              />
            </div>
          ))}
          <Form.Group className="col-12 pl-0">
            <Form.Label className="font-weight-medium">
              Job Lead Foreman:
              <small className="text-danger ml-1">*</small>
            </Form.Label>
            <Select
              required={true}
              isMulti={false}
              name="foremans"
              value={leadValue}
              className="basic-multi-select flex-grow-1 mt-2"
              options={selectJobLeadForemanOptions}
              classNamePrefix="select"
              onChange={({ value }) => setLeadId(value)}
            />
          </Form.Group>
          <Button
            className="mt-2"
            variant={"info"}
            size="sm"
            onClick={onNewCrew}
          >
            Add Another Crew
          </Button>
        </Modal.Body>
        <Modal.Footer className="d-flex col-12 justify-content-between">
          <Button
            size="sm"
            variant={"secondary"}
            onClick={() => onBack(crews, leadId)}
          >
            <span>
              <i className="mr-2 fas fa-chevron-left"></i>
              Back
            </span>
          </Button>
          <Button
            size="sm"
            className={"bg-primary"}
            variant={"primary"}
            type={"submit"}
          >
            Save Job
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default withErrorHandler(CrewSelectionModal);
