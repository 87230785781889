import React from "react";
import withErrorHandler from "../../error/WithErrorHandler";
import { Form } from "react-bootstrap";
import "./EmployeePayrollModal.scss";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-datetime/css/react-datetime.css";

import PayrollJobEntries from "./PayrollJobEntries";

const PayrollJob = ({ payrollJob, job }) => {
  return job ? (
    <AccordionItem key={`accordion-item-${job.id}`} uuid={job.id}>
      <AccordionItemHeading>
        <AccordionItemButton className="payroll-detail accordion__button">
          <span className="font-weight-medium">{`J${job.number || 0}`}</span> -{" "}
          <span>{`${job.so_number ? job.so_number + " / " : ""}${
            job.location
          }`}</span>
          <i className="fas fa-chevron-up float-right"></i>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <Form.Group key={`form-group-${job.id}`} className="col-12 p-0">
          {payrollJob.times.map((date, index) => {
            return (
              <PayrollJobEntries
                date={date}
                key={index}
                index={index}
                payrollJob={payrollJob}
              />
            );
          })}
        </Form.Group>
      </AccordionItemPanel>
    </AccordionItem>
  ) : null;
};

export default withErrorHandler(PayrollJob);
