import React from "react";
import "./Home.scss";
import logo from "./logo.png"; // Tell Webpack this JS file uses this image

const Home = () => {
  return (
    <img
      alt={"Faulkner"}
      className="align-self-center"
      src={logo}
      style={{ maxWidth: 500 }}
    />
  );
};

export default Home;
