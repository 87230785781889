import React, { useReducer, createContext, useContext } from "react";

const SECTION_HOME = 1;
const TYPE_EXPENSE_GENERAL = 1;
const EMPLOYEES = "employees";

const initialState = {
  jobDraft: null,
  job: null,
  jobDays: null,
  currentSection: SECTION_HOME,
  jobDetailHomeSectionAccordion: [],
  jobDetailExpensesSectionTab: TYPE_EXPENSE_GENERAL,
  jobDetailTimeSectionTab: EMPLOYEES,
  jobDetailExpensesAccordion: [],
  jobDetailCrewsSectionAccordion: [],
  jobDetailBackOfficeNotesAccordion: [],
  jobDetailTimeEmployeesAccordion: [],
};

const ACTIONS = {
  SET_JOB_DRAFT: "SET_JOB_DRAFT",
  SET_JOB: "SET_JOB",
  SET_JOB_DAYS: "SET_JOB_DAYS",
  SET_CURRENT_SECTION: "SET_CURRENT_SECTION",
  TOGGLE_ACCORDION: "TOGGLE_ACCORDION",
  TOGGLE_TAB: "TOGGLE_TAB",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.SET_JOB_DRAFT: {
      const { payload } = data;
      const { jobDraft } = payload;
      return {
        ...state,
        jobDraft,
      };
    }

    case ACTIONS.SET_JOB: {
      const { payload } = data;
      const { job } = payload;
      return {
        ...state,
        job,
        jobDetailHomeSectionAccordion: [
          ...state.jobDetailHomeSectionAccordion,
          job.postContract?.id,
        ],
        jobDetailBackOfficeNotesAccordion: job.notes?.length
          ? [job.notes[0]?.id]
          : [],
      };
    }

    case ACTIONS.SET_JOB_DAYS: {
      const { payload } = data;
      const { jobDays } = payload;
      return {
        ...state,
        jobDays,
        jobDetailHomeSectionAccordion: [
          ...state.jobDetailHomeSectionAccordion,
          ...jobDays.map((jd) => jd.id),
        ],
        jobDetailExpensesAccordion: jobDays.map((jd) => jd.id),
      };
    }

    case ACTIONS.SET_CURRENT_SECTION: {
      const { payload } = data;
      const { section } = payload;
      return {
        ...state,
        currentSection: section,
      };
    }

    case ACTIONS.TOGGLE_ACCORDION: {
      const { payload } = data;
      const { accordion, preExpanded } = payload;
      return {
        ...state,
        [accordion]: preExpanded,
      };
    }

    case ACTIONS.TOGGLE_TAB: {
      const { payload } = data;
      const { tab, active } = payload;
      return {
        ...state,
        [tab]: active,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};
const jobDetailContext = createContext(initialState);

const JobDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <jobDetailContext.Provider value={stateAndDispatch}>
      {children}
    </jobDetailContext.Provider>
  );
};

export const useJobDetail = () => useContext(jobDetailContext);

export { jobDetailContext, JobDetailsProvider, ACTIONS };
