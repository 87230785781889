import React from "react";
import withErrorHandler from "../../../error/WithErrorHandler";
import "./JobDetailTimeEmployees.scss";
import moment from "moment";
import { getAllCrew, getTimeAsHours } from "../../../Utils/Utils";

const JobDetailTimeEmployees = ({ job, jobDays }) => {
  const allCrew = getAllCrew(job);

  return (
    <div className="job-detail-time-employees p-0" role="table">
      <div className="times col-12 float-left p-0">
        <div className="flex-table header nowrap" role="rowgroup">
          {jobDays.map((jd, i) => (
            <div key={i} className="flex-row" role="columnheader">
              <span key={jd.id}>{moment(jd.date).format("dd MM.DD")}</span>
            </div>
          ))}
        </div>
        <div className="row-container">
          {allCrew.map((e, i) => (
            <div key={i} className="flex-table row nowrap" role="rowgroup">
              {jobDays.map((jd, k) => {
                const ed = jd.employeeDays.find(
                  (ed) => ed.employee_id === e.id
                );
                return (
                  <div key={`${i}-${k}`} className="flex-row" role="cell">
                    {ed ? (
                      <>
                        <span key={`${jd.id}-${e.id}-cl`}>
                          <i className="far fa-clock"></i>
                          {getTimeAsHours(
                            ed.clockEntries.reduce((p, c) => {
                              if (c.lunch_break > 0) {
                                p = p - c.lunch_break;
                              }
                              return p + c.duration;
                            }, 0)
                          )}
                        </span>
                        <span key={`${jd.id}-${e.id}-tr`}>
                          <i className="fas fa-car"></i>
                          {getTimeAsHours(
                            ed.trips.reduce((p, c) => p + c.duration, 0)
                          )}
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withErrorHandler(JobDetailTimeEmployees);
