import React from "react";
import withErrorHandler from "../../error/WithErrorHandler";
import { Table } from "react-bootstrap";
import "./EmployeePayrollModal.scss";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-datetime/css/react-datetime.css";

import { getTimeAsHours } from "../../Utils/Utils";
import PayrollClockEntry from "./PayrollClockEntry";
import PayrollTravelEntry from "./PayrollTravelEntry";

const PayrollJobEntries = ({ date, index, payrollJob }) => {
  return (
    <div className="col-12 mb-2 padding-row-form-group">
      <div className="border text-center d-flex py-2 bg-light px-5">
        <div className="col-4 text-left">
          <i className="far fa-calendar text-primary mr-2 fa-lg"></i>
          {moment(date.date).format("dd MM.DD")}
        </div>
        <div className="col-4">
          <i className="far fa-clock text-primary mr-2 fa-lg"></i>
          {getTimeAsHours(date.time - date.lunch_break).toString()}
        </div>
        <div className="col-4 text-right">
          <i className="fas fa-car text-primary mr-2 fa-lg"></i>
          {getTimeAsHours(date.travel_time).toString()}
        </div>
      </div>
      {date.clockEntries.length > 0 || date.trips.length > 0 ? (
        <div
          key={`accordion-item-button-${payrollJob.job_id}-${index}`}
          className="col-12 inline-flex p-0"
        >
          <Accordion
            className="col-12 p-0"
            allowZeroExpanded={true}
            allowMultipleExpanded={true}
            preExpanded={[]}
          >
            <AccordionItem
              key={`accordion-item-${payrollJob.job_id}-${index}`}
              uuid={`${payrollJob.job_id}-${index}`}
            >
              <AccordionItemHeading>
                <AccordionItemButton className="payroll-detail accordion__button small align-self-center">
                  <div className="mx-2">
                    Details
                    <i className="fas fa-chevron-up float-right"></i>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {date.clockEntries.length ? (
                  <Table className="table-fixed text-center rounded border mb-0">
                    <thead>
                      <tr className="small font-weight-medium text-muted bg-light">
                        <th className="col-2">Type</th>
                        <th className="col-5">Start Time</th>
                        <th className="col-5">End Time</th>
                        <th className="col-5">Lunch Break</th>
                      </tr>
                    </thead>
                    <tbody>
                      {date.clockEntries.map((entry, index) => {
                        return (
                          <PayrollClockEntry
                            entry={entry}
                            key={index}
                            index={index}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                ) : null}
                {date.trips.length ? (
                  <Table className="table-fixed text-center rounded border mb-0">
                    <thead>
                      <tr className="small font-weight-medium text-muted bg-light">
                        <th className="col-2">Type</th>
                        <th className="col-5">Start Time</th>
                        <th className="col-5">End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {date.trips.map((entry, index) => {
                        return (
                          <PayrollTravelEntry
                            entry={entry}
                            key={index}
                            index={index}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      ) : null}
    </div>
  );
};

export default withErrorHandler(PayrollJobEntries);
