import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CustomerDetail from "./Customers/CustomerDetail";
import CustomerList from "./Customers/CustomersList";
import EmployeesList from "./Employees/EmployeesList";
import Home from "./Home";
import JobDetail from "./Jobs/Detail/JobDetail";
import JobsScreen from "./Jobs/JobsScreen";
import Login from "./Login";
import Navigation from "./Navigation";
import Payroll from "./Payroll/Payroll";
import Expenses from "./Expenses/Expenses";
import { useAuth } from "./providers/authProvider";
import Api from "./api/Api";

export default () => (
  <BrowserRouter>
    <Navigation></Navigation>
    <div className="sections-container d-flex flex-column">
      <Switch>
        <PrivateRoute path="/payroll" exact component={Payroll} />
        <PrivateRoute path="/jobs" exact component={JobsScreen} />
        <PrivateRoute path="/jobs/:jobId" exact component={JobDetail} />
        <PrivateRoute path="/employees" exact component={EmployeesList} />
        <PrivateRoute path="/customers" exact component={CustomerList} />
        <PrivateRoute
          path="/customers/:customerId"
          exact
          component={CustomerDetail}
        />
        <PrivateRoute path="/expenses" exact component={Expenses} />
        <Route path="/login" exact component={Login} />
        <Route path="/home" exact component={Home} />
        <Route path="*" component={Home} />
      </Switch>
    </div>
  </BrowserRouter>
);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Api.validate();
        if (!result) {
          setRedirectToLogin(true);
        } else {
          setAuthContext({ currentUser: result });
        }
      } catch (err) {}
    };
    if (!authContext.currentUser) {
      fetchData();
    }
  }, [authContext.currentUser, setAuthContext]);

  return (
    <Route
      {...rest}
      render={(props) =>
        redirectToLogin ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
