import React from "react";
import withErrorHandler from "../error/WithErrorHandler";
import "./ChangeOrder.scss";
import moment from "moment";

const ChangeOrder = ({ item, postContract }) => {
  return (
    <div className="col-12 p-4 change-order-component border rounded bg-white text-left">
      <p>
        <span className="wo-date">{moment(item.date).format("MM/DD/YY")}</span>
      </p>
      <p>
        <span className="wo-description-title">Scope of Work</span>
      </p>
      <p>{item.description}</p>
      <div className="row">
        <div className="employee-hours-container col-6 p-0">
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">Foremen Hours: </span>
              <span className="col-5 bg-primary">
                <i className="fas fa-clock"></i>
                {`${item.foreman_hours ? item.foreman_hours : "0"} hs`}
              </span>
            </div>
          </div>
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">Foremen Overtime: </span>
              <span className="col-5 bg-primary">
                <i className="fas fa-clock"></i>
                {`${
                  item.foreman_overtime_hours
                    ? item.foreman_overtime_hours
                    : "0"
                } hs`}
              </span>
            </div>
          </div>
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">Technicians Hours: </span>
              <span className="col-5 bg-primary">
                <i className="fas fa-clock"></i>
                {`${item.technician_hours ? item.technician_hours : "0"} hs`}
              </span>
            </div>
          </div>
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">
                Technicians Overtime:{" "}
              </span>
              <span className="col-5 bg-primary">
                <i className="fas fa-clock"></i>
                {`${
                  item.technician_overtime_hours
                    ? item.technician_overtime_hours
                    : "0"
                } hs`}
              </span>
            </div>
          </div>
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">Management Hours: </span>
              <span className="col-5 bg-primary">
                <i className="fas fa-clock"></i>
                {`${item.management_hours ? item.management_hours : "0"} hs`}
              </span>
            </div>
          </div>
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">
                Management Overtime:{" "}
              </span>
              <span className="col-5 bg-primary">
                <i className="fas fa-clock"></i>
                {`${
                  item.management_overtime_hours
                    ? item.management_overtime_hours
                    : "0"
                } hs`}
              </span>
            </div>
          </div>
        </div>

        <div className="employee-hours-container col-6 p-0">
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">
                Client Representative:{" "}
              </span>
              <span className="col-5 bg-primary">
                {postContract.signature_name}
              </span>
            </div>
          </div>
          <div className="employee-hours col-12">
            <div className="col-12 p-0 employee-hour-container overflow-hidden">
              <span className="col-7 dark-bg-primary">Date: </span>
              <span className="col-5 bg-primary">
                {item.created_at
                  ? moment(item.created_at).format("DD/MM/YY hh:mm A")
                  : ""}
              </span>
            </div>
          </div>
          {item.parts_material ? (
            <p className="mt-2 employee-hours col-12">
              <span>Parts / Material:</span>
              <span>{item.parts_material}</span>
            </p>
          ) : null}
        </div>

        <div className="col-6 horizontal-center-content">
          {item.signature_url ? (
            <>
              <span>Signature:</span>
              <p>
                <img
                  className="client-signature"
                  src={item.signature_url}
                  alt="Client signature"
                />
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorHandler(ChangeOrder);
