import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import withErrorHandler from "../../../error/WithErrorHandler";
import "./JobDetailStandByTime.scss";
import moment from "moment";

const JobDetailStandByTime = ({ jobDays }) => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [sbtDetail, setSbtDetail] = useState({});

  const handleDetailModalClose = () => {
    setShowDetailModal(false);
    setSbtDetail({});
  };

  const sbts = jobDays.reduce(
    (p, c) => [
      ...p,
      ...c.standByTime.map((sbt) => Object.assign(sbt, { date: c.date })),
    ],
    []
  );
  return (
    <>
      {sbts.length ? (
        <>
          {sbts.map((sbt) => (
            <div
              onClick={() => {
                setShowDetailModal(true);
                setSbtDetail(sbt);
              }}
              key={sbt.id}
              className="col-12 flex-table row stand-by-time-row"
              role="rowgroup"
            >
              <div className="flex-row" role="cell">
                <i className="fas fa-stopwatch"></i>
                <span>{moment(sbt.date).format("MMMM DD - YYYY")}</span>
                <span className="stand-by-time-creator">
                  (
                  {sbt.creator
                    ? `${sbt.creator.firstName} ${sbt.creator.lastName}`
                    : "-"}
                  )
                </span>
              </div>
              <span className="stand-by-hours">
                {sbt.foreman_hours + sbt.technician_hours} hs
              </span>
            </div>
          ))}
          <div className="mt-3 text-right">
            <span>Total extra:</span>
            <span className="text-primary font-weight-medium total-hours">
              {sbts.reduce(
                (p, c) => p + c.foreman_hours + c.technician_hours,
                0
              )}{" "}
              hs
            </span>
          </div>
          <Modal
            className="stand-by-time-modal"
            show={showDetailModal}
            onHide={handleDetailModalClose}
          >
            <Modal.Header className="font-weight-medium" closeButton>
              Stand By Time Detail
            </Modal.Header>
            <Modal.Body>
              <>
                <p>{sbtDetail.description}</p>
                <p>
                  <span className="stand-by-time-date">
                    {moment(sbtDetail.date).format("MMMM DD - YYYY")}
                  </span>
                  <span className="stand-by-time-creator">
                    (
                    {sbtDetail.creator
                      ? `${sbtDetail.creator.firstName} ${sbtDetail.creator.lastName}`
                      : "-"}
                    )
                  </span>
                </p>
                <div className="employee-hours-container">
                  <div className="employee-hours">
                    <div className="col-12 col-md-8 p-0 employee-hour-container overflow-hidden">
                      <span className="col-7 dark-bg-primary">Foremans: </span>
                      <span className="col-5 bg-primary text-center">
                        <i className="fas fa-clock"></i>
                        {`${sbtDetail.foreman_hours} hs`}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 employee-hours">
                    <div className="col-12 col-md-8 p-0 employee-hour-container overflow-hidden">
                      <span className="col-7 dark-bg-primary">
                        Technicians:{" "}
                      </span>
                      <span className="col-5 bg-primary text-center">
                        <i className="fas fa-clock"></i>
                        {`${sbtDetail.technician_hours} hs`}
                      </span>
                    </div>
                  </div>
                </div>
                <p>
                  Client Signature:
                  {sbtDetail.signature_url ? (
                    <p>
                      <img
                        className="client-signature"
                        src={sbtDetail.signature_url}
                        alt="Client Signature"
                      />
                      <small className="float-right">
                        {sbtDetail.signature_name}
                      </small>
                    </p>
                  ) : (
                    <span className="ml-1 text-muted">Not provided</span>
                  )}
                </p>
              </>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <div className="flex-table row no-data-row" role="rowgroup">
          <div className="flex-row" role="cell">
            No stand by time recorded
          </div>
        </div>
      )}
    </>
  );
};

export default withErrorHandler(JobDetailStandByTime);
