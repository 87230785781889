import React, { Component } from "react";
import FallbackComponent from "./FallbackComponent";

const withErrorHandler = (WrappedComponent, callback) => {
  class WithErrorHandler extends Component {
    constructor() {
      super();
      this.state = {
        error: null,
        info: null,
      };
    }

    componentDidCatch(error, info) {
      this.setState({ error: error, info: info });

      /*
            Ideally this callback should be a service that reports and rolls up
            errors so we can address them.  
            */
      if (callback) {
        callback(error, info, this.props);
      }
    }

    render() {
      if (this.state.error) {
        return <FallbackComponent></FallbackComponent>;
      } else {
        return <WrappedComponent {...this.props} />;
      }
    }
  }
  return WithErrorHandler;
};

export default withErrorHandler;
