import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import withErrorHandler from "../error/WithErrorHandler";
import { Tabs, Tab, Spinner, Button } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";

import DateControls from "../Payroll/DateControls";
import CustomDateFilter from "./CustomDateFilter";

import Api from "../api/Api";

import "./Expenses.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import DateRangePicker from "./DateRangePicker";

const REPORT_TYPE_BY_JOB = 1;
const REPORT_TYPE_BY_PAYROLL_WEEK = 2;
const REPORT_TYPE_BY_CUSTOM_DATES = 3;

const Expenses = () => {
  const [columnDefs] = useState([
    {
      field: "Date",
      valueFormatter: function (data) {
        return moment(data.value).format("MM/DD/YYYY");
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterValue, cellValue) => {
          filterValue = moment(filterValue).format("MM/DD/YYYY");
          if (cellValue < filterValue) {
            return -1;
          } else if (cellValue > filterValue) {
            return 1;
          }
          return 0;
        },
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "SO Number",
      valueFormatter: function (data) {
        return data.value ? data.value : "-";
      },
      comparator: (filterValue, cellValue) => {
        const filterValueToLowerCase = filterValue.toLowerCase();
        const cellValueToLowerCase = cellValue.toLowerCase();
        if (cellValueToLowerCase < filterValueToLowerCase) {
          return -1;
        } else if (cellValueToLowerCase > filterValueToLowerCase) {
          return 1;
        }
        return 0;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "Customer",
      valueFormatter: function (data) {
        return data.value ? data.value : "-";
      },
      comparator: (filterValue, cellValue) => {
        const filterValueToLowerCase = filterValue.toLowerCase();
        const cellValueToLowerCase = cellValue.toLowerCase();
        if (cellValueToLowerCase < filterValueToLowerCase) {
          return -1;
        } else if (cellValueToLowerCase > filterValueToLowerCase) {
          return 1;
        }
        return 0;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "Type",
      valueFormatter: function (data) {
        return data.value ? data.value : "-";
      },
      comparator: (filterValue, cellValue) => {
        const filterValueToLowerCase = filterValue.toLowerCase();
        const cellValueToLowerCase = cellValue.toLowerCase();
        if (cellValueToLowerCase < filterValueToLowerCase) {
          return -1;
        } else if (cellValueToLowerCase > filterValueToLowerCase) {
          return 1;
        }
        return 0;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "Amount",
      valueFormatter: function (data) {
        return data.value ? data.value : "-";
      },
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "Description",
      valueFormatter: function (data) {
        return data.value ? data.value : "-";
      },
      comparator: (filterValue, cellValue) => {
        const filterValueToLowerCase = filterValue.toLowerCase();
        const cellValueToLowerCase = cellValue.toLowerCase();
        if (cellValueToLowerCase < filterValueToLowerCase) {
          return -1;
        } else if (cellValueToLowerCase > filterValueToLowerCase) {
          return 1;
        }
        return 0;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "Photo",
      cellRenderer: (data) =>
        data.value ? (
          <a href={data.value} target="_blank" rel="noopener noreferrer">
            Click here
          </a>
        ) : (
          "-"
        ),
      comparator: (filterValue, cellValue) => {
        const filterValueToLowerCase = filterValue.toLowerCase();
        const cellValueToLowerCase = cellValue.toLowerCase();
        if (cellValueToLowerCase < filterValueToLowerCase) {
          return -1;
        } else if (cellValueToLowerCase > filterValueToLowerCase) {
          return 1;
        }
        return 0;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
    {
      field: "CreatedBy",
      valueFormatter: function (data) {
        return data.value ? data.value : "-";
      },
      comparator: (filterValue, cellValue) => {
        const filterValueToLowerCase = filterValue.toLowerCase();
        const cellValueToLowerCase = cellValue.toLowerCase();
        if (cellValueToLowerCase < filterValueToLowerCase) {
          return -1;
        } else if (cellValueToLowerCase > filterValueToLowerCase) {
          return 1;
        }
        return 0;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        debounceMs: 200,
      },
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      flex: 1,
    }),
    []
  );

  const gridOptions = {
    // Here is where we specify the component to be used as the date picker widget
    components: {
      agDateInput: CustomDateFilter,
    },
  };

  const [jobsData, setJobsData] = useState([]);
  const [payrollData, setPayrollData] = useState([]);
  const [customDatesData, setCustomDatesData] = useState([]);
  const [jobLoading, setJobLoading] = useState(false);
  const [payrollLoading, setPayrollLoading] = useState(false);
  const [customDatesLoading, setCustomDatesLoading] = useState(false);
  const [genaratePDFLoading, setGenaratePDFLoading] = useState(false);

  const [reportType, setReportType] = useState(REPORT_TYPE_BY_JOB);
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").toDate()
  );
  const [, setEndDate] = useState(moment().endOf("isoWeek").toDate());

  const [originalStartDate, setOriginalStartDate] = useState(
    moment().startOf("isoWeek").toDate()
  );

  const [customStartDate, setCustomStartDate] = useState(moment());
  const [customEndDate, setCustomEndDate] = useState(moment());

  const [originalCustomStartDate, setOriginalCustomStartDate] = useState(
    moment()
  );
  const [originalCustomEndDate, setOriginalCustomEndDate] = useState(moment());

  const jobGridRef = useRef();
  const payrollGridRef = useRef();
  const customDatesGridRef = useRef();

  useEffect(() => {
    Api.getJobs()
      .then((data) => {
        setJobs(data.data.jobs);
        const j = data.data.jobs[0];
        setCurrentJob({
          key: j.id,
          label: `${j.so_number ? j.so_number + " - " : ""}${j.name}`,
          value: j.id,
        });
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      });
  }, []);

  useEffect(() => {
    if (currentJob) {
      setJobLoading(true);
      Api.getExpensesReport({
        reportType: REPORT_TYPE_BY_JOB,
        job_id: currentJob.value,
      })
        .then((data) => {
          const result = data?.data.map((e) => ({
            ...e,
            "SO Number": e.SONumber,
          }));
          setJobsData(result);
          setJobLoading(false);
        })
        .catch((err) => {
          const msg = err.response?.data?.length
            ? err.response.data[0].msg
            : "There was an error with your request";
          toast.error(msg);
          setJobLoading(false);
        });
    }
  }, [currentJob]);

  const dateSelection = (start_date, end_date) => {
    setStartDate(start_date);
    setEndDate(end_date);
  };

  const onJobExportCSV = useCallback(() => {
    jobGridRef.current.api.exportDataAsCsv({
      fileName: "Job Expenses",
    });
  }, []);

  const onPayrollExportCSV = useCallback(() => {
    payrollGridRef.current.api.exportDataAsCsv({
      fileName: "Payroll Week Expenses",
    });
  }, []);

  const onCustomDatesExportCSV = useCallback(() => {
    customDatesGridRef.current.api.exportDataAsCsv({
      fileName: "Custom Date Range Expenses",
    });
  }, []);

  const onSelection = (from, to) => {
    setCustomStartDate(from);
    setCustomEndDate(to);
  };

  useEffect(() => {
    setPayrollLoading(true);
    Api.getExpensesReport({
      reportType: REPORT_TYPE_BY_PAYROLL_WEEK,
      startDate: moment(startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: moment(startDate).endOf("isoWeek").format("YYYY-MM-DDT23:59:59"),
    })
      .then((data) => {
        const result = data?.data.map((e) => ({
          ...e,
          "SO Number": e.SONumber,
        }));
        setOriginalStartDate(startDate);
        setPayrollData(result);
        setPayrollLoading(false);
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setPayrollLoading(false);
      });
  }, [startDate]);

  useEffect(() => {
    setCustomDatesLoading(true);
    Api.getExpensesReport({
      reportType: REPORT_TYPE_BY_PAYROLL_WEEK,
      startDate: moment(customStartDate).format("YYYY-MM-DDT00:00:00"),
      endDate: moment(customEndDate).format("YYYY-MM-DDT23:59:59"),
    })
      .then((data) => {
        const result = data?.data.map((e) => ({
          ...e,
          "SO Number": e.SONumber,
        }));
        setOriginalCustomStartDate(customStartDate);
        setOriginalCustomEndDate(customEndDate);
        setCustomDatesData(result);
        setCustomDatesLoading(false);
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setCustomDatesLoading(false);
      });
  }, [customStartDate, customEndDate]);

  const generateJobPDF = async () => {
    setGenaratePDFLoading(true);
    const response = await Api.getExpensesReportPDF({
      reportType: REPORT_TYPE_BY_JOB,
      job_id: currentJob.value,
    });
    const data = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement("a");
    tempLink.href = pdfURL;
    tempLink.hidden = true;
    tempLink.setAttribute("download", `Expenses.pdf`);
    tempLink.click();
    setGenaratePDFLoading(false);
  };

  const generatePayrollWeekPDF = async () => {
    setGenaratePDFLoading(true);
    const response = await Api.getExpensesReportPDF({
      reportType: REPORT_TYPE_BY_PAYROLL_WEEK,
      startDate: moment(startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: moment(startDate).endOf("isoWeek").format("YYYY-MM-DDT23:59:59"),
    });
    const data = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement("a");
    tempLink.href = pdfURL;
    tempLink.hidden = true;
    tempLink.setAttribute("download", `Expenses.pdf`);
    tempLink.click();
    setGenaratePDFLoading(false);
  };

  const generateCustomDatesPDF = async () => {
    setGenaratePDFLoading(true);
    const response = await Api.getExpensesReportPDF({
      reportType: REPORT_TYPE_BY_PAYROLL_WEEK,
      startDate: moment(customStartDate).format("YYYY-MM-DDT00:00:00"),
      endDate: moment(customEndDate).format("YYYY-MM-DDT23:59:59"),
    });
    const data = new Blob([response.data], { type: "application/pdf" });
    const pdfURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement("a");
    tempLink.href = pdfURL;
    tempLink.hidden = true;
    tempLink.setAttribute("download", `Expenses.pdf`);
    tempLink.click();
    setGenaratePDFLoading(false);
  };

  return (
    <div className="expenses-reports flex-grow-1 d-flex flex-column">
      <Tabs
        className="font-weight-medium job-detail-time-section p-0"
        activeKey={reportType}
        onSelect={(k) => setReportType(parseInt(k))}
      >
        <Tab eventKey={REPORT_TYPE_BY_JOB} title="Report by Job">
          <div className="d-flex align-items-end justify-content-between">
            <div className="col-3 d-flex align-items-center mt-2">
              <span className="mr-2 font-weight-medium">Jobs</span>
              <Select
                required={true}
                isMulti={false}
                name="jobs"
                value={currentJob}
                className="flex-grow-1 w-100 justify-content-start border rounded"
                options={jobs.map((j) => ({
                  key: j.id,
                  label: `${j.so_number ? j.so_number + " - " : ""}${j.name}`,
                  value: j.id,
                }))}
                classNamePrefix="select"
                onChange={(value) => setCurrentJob(value)}
              />
            </div>
            <div>
              {genaratePDFLoading ? (
                <Spinner
                  className="mr-5"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Button
                  onClick={generateJobPDF}
                  className="btn-sm btn-danger mr-2"
                >
                  Export PDF
                </Button>
              )}
              <Button onClick={onJobExportCSV} className="btn-sm bg-primary">
                Export CSV
              </Button>
            </div>
          </div>
          {currentJob ? (
            jobLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <div className="px-2 flex-grow-1 d-flex flex-column">
                <div className="rounded border w-100 my-4 flex-grow-1">
                  <div
                    className="ag-theme-alpine text-left"
                    style={{ height: "100%" }}
                  >
                    <AgGridReact
                      ref={jobGridRef}
                      rowData={jobsData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      gridOptions={gridOptions}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            )
          ) : null}
        </Tab>
        <Tab
          eventKey={REPORT_TYPE_BY_PAYROLL_WEEK}
          title="Report by Payroll Week"
        >
          <div className="d-flex align-items-end justify-content-between">
            <div className="d-flex align-items-center mt-2">
              <DateControls
                startDate={startDate}
                originalStartDate={originalStartDate}
                doSubmit={dateSelection}
              />
            </div>
            <div>
              {genaratePDFLoading ? (
                <Spinner
                  className="mr-5"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Button
                  onClick={generatePayrollWeekPDF}
                  className="btn-sm btn-danger mr-2"
                >
                  Export PDF
                </Button>
              )}
              <Button
                onClick={onPayrollExportCSV}
                className="btn-sm bg-primary"
              >
                Export CSV
              </Button>
            </div>
          </div>
          {reportType === REPORT_TYPE_BY_PAYROLL_WEEK ? (
            payrollLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <div className="px-2 flex-grow-1 d-flex flex-column">
                <div className="rounded border w-100 my-4 flex-grow-1">
                  <div
                    className="ag-theme-alpine text-left"
                    style={{ height: "100%" }}
                  >
                    <AgGridReact
                      ref={payrollGridRef}
                      rowData={payrollData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            )
          ) : null}
        </Tab>
        <Tab
          eventKey={REPORT_TYPE_BY_CUSTOM_DATES}
          title="Report by Custom Date Range"
        >
          <div className="d-flex align-items-end justify-content-between">
            <div className="d-flex align-items-center mt-2">
              <DateRangePicker
                disabledAfter={new Date()}
                originalStartDate={moment(originalCustomStartDate)}
                originalEndDate={moment(originalCustomEndDate)}
                startDate={moment(customStartDate)}
                endDate={moment(customEndDate)}
                doSubmit={onSelection}
              />
            </div>
            <div>
              {genaratePDFLoading ? (
                <Spinner
                  className="mr-5"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Button
                  onClick={generateCustomDatesPDF}
                  className="btn-sm btn-danger mr-2"
                >
                  Export PDF
                </Button>
              )}
              <Button
                onClick={onCustomDatesExportCSV}
                className="btn-sm bg-primary"
              >
                Export CSV
              </Button>
            </div>
          </div>
          {reportType === REPORT_TYPE_BY_CUSTOM_DATES ? (
            customDatesLoading ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <div className="px-2 flex-grow-1 d-flex flex-column">
                <div className="rounded border w-100 my-4 flex-grow-1">
                  <div
                    className="ag-theme-alpine text-left"
                    style={{ height: "100%" }}
                  >
                    <AgGridReact
                      ref={customDatesGridRef}
                      rowData={customDatesData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            )
          ) : null}
        </Tab>
      </Tabs>
    </div>
  );
};

export default withErrorHandler(Expenses);
