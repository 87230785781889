import React, { useState } from "react";
import withErrorHandler from "./error/WithErrorHandler";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import "./Login.scss";
import Api from "./api/Api";
import { Redirect, withRouter } from "react-router-dom";
import { useAuth } from "./providers/authProvider";

const Login = ({ location, history }) => {
  const { from } = location.state || { from: { pathname: "/jobs" } };

  const [, setAuthContext] = useAuth();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  const submit = (evt) => {
    evt.preventDefault();
    setLoading(true);
    Api.login({
      email,
      password,
    })
      .then((result) => {
        setAuthContext({ currentUser: result.data });
        setError(null);
        setRedirectToReferrer(true);
      })
      .catch((err) => {
        if (!err.response) {
          setLoading(false);
          setError("Server Unreachable");
          setTimeout(() => setError(null), 2000);
        } else if (err.response.status === 401 || err.response.status === 400) {
          setLoading(false);
          setError("Invalid Credentials");
          setTimeout(() => setError(null), 2000);
        } else if (err.response.status === 422) {
          setLoading(false);
          setError("Inexistent user");
          setTimeout(() => setError(null), 2000);
        }
      });
  };

  return (
    <Modal
      className="login-modal"
      show={true}
      onHide={() => history.push("home")}
    >
      <Form className="login-form" onSubmit={error ? null : submit}>
        <Modal.Header closeButton>
          <Modal.Title>Log In</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-4">
          <Form.Group className="col-12 p-0">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email..."
              value={email}
              onChange={(evt) => setEmail(evt.currentTarget.value)}
              name="email"
              required={true}
            />
          </Form.Group>
          <Form.Group className="col-12 p-0 mb-0">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password..."
              value={password}
              onChange={(evt) => setPassword(evt.currentTarget.value)}
              name="password"
              required={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-4">
          <Button
            className={`${
              error ? "" : "bg-primary"
            } flex-grow-1 font-weight-medium sign-in-btn`}
            variant={error ? "danger" : "primary"}
            type="submit"
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : error ? (
              <span>{error}</span>
            ) : (
              "Sign In"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default withErrorHandler(withRouter(Login));
