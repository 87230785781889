import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  touchedClocks: [],
  touchedTrips: [],
  jobs: [],
  payrollData: [],
  bonus: {},
};

const ACTIONS = {
  INIT: "INIT",
  CHANGE_BONUS: "CHANGE_BONUS",
  CHANGE_TOUCHED_CLOCKS: "CHANGE_TOUCHED_CLOCKS",
  CHANGE_TOUCHED_TRIPS: "CHANGE_TOUCHED_TRIPS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.INIT: {
      const { payload } = data;
      const { jobs, payrollData, bonus } = payload;
      return {
        ...initialState,
        jobs,
        payrollData,
        bonus,
      };
    }

    case ACTIONS.CHANGE_TOUCHED_TRIPS: {
      const { payload } = data;
      const { touchedTrip } = payload;
      const touchedTrips = [...state.touchedTrips];
      const index = touchedTrips.findIndex((e) => e.id === touchedTrip.id);
      if (index > -1) {
        touchedTrips.splice(index, 1, touchedTrip);
      } else {
        touchedTrips.push(touchedTrip);
      }
      return {
        ...state,
        touchedTrips,
      };
    }

    case ACTIONS.CHANGE_TOUCHED_CLOCKS: {
      const { payload } = data;
      const { touchedClock } = payload;
      const touchedClocks = [...state.touchedClocks];
      const index = touchedClocks.findIndex((e) => e.id === touchedClock.id);
      if (index > -1) {
        touchedClocks.splice(index, 1, touchedClock);
      } else {
        touchedClocks.push(touchedClock);
      }
      return {
        ...state,
        touchedClocks,
      };
    }

    case ACTIONS.CHANGE_BONUS: {
      const { payload } = data;
      const { bonus } = payload;
      return {
        ...state,
        bonus,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};
const payrollContext = createContext(initialState);

const PayrollProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <payrollContext.Provider value={stateAndDispatch}>
      {children}
    </payrollContext.Provider>
  );
};

export const usePayroll = () => useContext(payrollContext);

export { payrollContext, PayrollProvider, ACTIONS };
