import React, { useEffect, useState } from "react";
import withErrorHandler from "../../../error/WithErrorHandler";
import "./JobDetailHomeSection.scss";
import { Button, Spinner } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Api from "../../../api/Api";
import ConfirmModal from "../../../Utils/ConfirmModal";
import CrewCreationModal from "./CrewCreationModal";
import { toast } from "react-toastify";
import JobCrew from "../../JobCrew";
import { ACTIONS, useJobDetail } from "../../../providers/jobDetailsProvider";

const ACCORDION_KEY = "jobDetailCrewsSectionAccordion";

const JobDetailCrewsSection = ({ doRefresh }) => {
  const [jobDetailContext, setJobDetailContext] = useJobDetail();

  const [loading, setLoading] = useState();
  const [removeCrew, setRemoveCrew] = useState();
  const [creationModal, setCreationModal] = useState();
  const [crews, setCrews] = useState([]);

  useEffect(() => {
    setCrews(jobDetailContext.job.crews);
  }, [jobDetailContext.job.crews]);

  const onRemoveCrew = (crew) => {
    const isLeadCrew = crew.foremans.some(
      (f) => jobDetailContext.job.lead_id === f.employee_id
    );
    if (isLeadCrew) {
      return toast.error(
        "Can't remove the crew of the lead foreman of the job."
      );
    }
    setRemoveCrew(crew.id);
  };

  const onSubmitRemoveCrew = () => {
    setLoading(true);
    Api.deleteCrew(removeCrew)
      .then(() => {
        setLoading(false);
        setRemoveCrew(false);
        toast.success("Crew deleted successfully.");
        doRefresh();
      })
      .catch((err) => {
        setLoading(false);
        setRemoveCrew(false);
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      });
  };

  const onSubmitCreateCrew = (crew) => {
    setLoading(true);
    setCreationModal(false);
    Api.postCrew({ ...crew, job_id: jobDetailContext.job.id })
      .then(() => {
        setLoading(false);
        toast.success("Crew created successfully.");
        doRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const onSubmitUpdateCrew = (crew) => {
    setLoading(true);
    Api.putCrew({
      ...crew,
      job_id: jobDetailContext.job.id,
    })
      .then(() => {
        setLoading(false);
        toast.success("Crew updated successfully.");
        doRefresh();
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const updateCrew = (crew) => {
    const index = crews.findIndex((c) => c.id === crew.id);
    const updatedCrews = [...crews];
    updatedCrews.splice(index, 1, crew);
    setCrews(updatedCrews);
  };

  return (
    <>
      {crews.length ? (
        <Accordion
          onChange={(preExpanded) => {
            setJobDetailContext({
              action: ACTIONS.TOGGLE_ACCORDION,
              payload: {
                accordion: ACCORDION_KEY,
                preExpanded,
              },
            });
          }}
          preExpanded={jobDetailContext[ACCORDION_KEY]}
          allowZeroExpanded={true}
          allowMultipleExpanded={true}
        >
          {crews
            .sort((x, y) => y.lead - x.lead)
            .map((crew) => {
              const leadForeman = crew.foremans.some((f) => f.is_lead)
                ? crew.foremans.find((f) => f.is_lead)
                : null;
              const leadForemanName = leadForeman
                ? leadForeman.employee.name ||
                  `${leadForeman.employee.firstName} ${leadForeman.employee.lastName}`
                : "No Lead";
              return (
                <AccordionItem
                  className="accordion__item"
                  key={crew.id}
                  uuid={crew.id}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="job-detail-accordion-btn accordion__button d-flex justify-content-between align-items-baseline">
                      <span>
                        {leadForemanName}'s Crew -{" "}
                        {crew.foremans.length + crew.technicians.length}/
                        {crew.crew_size}
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="my-4 d-flex-not-important justify-content-between align-items-baseline">
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <JobCrew
                        crew={crew}
                        setCrew={updateCrew}
                        onSubmit={onSubmitUpdateCrew}
                        onRemove={onRemoveCrew}
                      />
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      ) : (
        <div className="flex-table row no-data-row" role="rowgroup">
          <div className="flex-row" role="cell">
            No crews
          </div>
        </div>
      )}
      <Button
        onClick={() => setCreationModal(true)}
        className="btn-success float-left mt-2 ml-1"
      >
        Add Crew
      </Button>
      {creationModal ? (
        <CrewCreationModal
          onClose={() => setCreationModal(false)}
          onSubmit={onSubmitCreateCrew}
        />
      ) : null}
      {removeCrew ? (
        <ConfirmModal
          submit={onSubmitRemoveCrew}
          onClose={() => setRemoveCrew(false)}
          variant="danger"
          body={`Are you sure you want to delete the selected crew? All the photos and notes will be removed too.`}
          title="Delete Crew"
        />
      ) : null}
    </>
  );
};

export default withErrorHandler(JobDetailCrewsSection);
