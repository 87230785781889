import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/Api";
import { useAuth } from "../../../providers/authProvider";
import { useJobDetail } from "../../../providers/jobDetailsProvider";
import { getNewCrewNotes } from "../../../Utils/Utils";
import moment from "moment";

const ATTACHMENT_TYPE_NOTE = 2;

const JobDetailCrewNotesSection = () => {
  const [jobDetailContext] = useJobDetail();
  const [authContext, setAuthContext] = useAuth();

  const [noteAttachments, setNoteAttachments] = useState([]);

  useEffect(() => {
    let allNotes = [];
    jobDetailContext.jobDays.forEach((jd) => {
      jobDetailContext.job.crews.forEach((crew) => {
        const attachments = jd.attachments.filter(
          (at) => at.crew_id === crew.id
        );
        const noteAttachments = attachments.filter(
          (a) => a.type === ATTACHMENT_TYPE_NOTE
        );
        allNotes = allNotes.concat(noteAttachments);
      });
    });
    setNoteAttachments(allNotes);
  }, [jobDetailContext.job.crews, jobDetailContext.jobDays]);

  useEffect(() => {
    const cleanNewCrewNotes = async () => {
      try {
        await Api.putEmployee({
          ...authContext.currentUser,
          lastSyncCrewNotes: new Date(),
        });
        setAuthContext({
          ...authContext,
          currentUser: {
            ...authContext.currentUser,
            last_sync_crew_notes: moment().toDate(),
          },
        });
      } catch (err) {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
      }
    };
    const newCrewNotes = getNewCrewNotes(
      authContext,
      jobDetailContext.jobDays,
      jobDetailContext.job
    );
    if (newCrewNotes.length) {
      cleanNewCrewNotes();
    }
  }, [
    authContext,
    setAuthContext,
    jobDetailContext.job,
    jobDetailContext.jobDays,
  ]);

  return (
    <Row className="inner-section">
      <div className="col-12 text-left p-0 font-weight-medium">
        <span className="font-weight-medium">NOTES</span>
        <span className="quantity ml-1"> ({noteAttachments.length})</span>
      </div>
      {noteAttachments.length ? (
        <div className="mt-2 notes-container col-12 px-0">
          {noteAttachments.map((a) => (
            <div className="note text-left" key={a.id}>
              <span>{`${a.label}: ${a.content}`}</span>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default JobDetailCrewNotesSection;
